import React, { useState, useEffect } from "react";
import {
    Modal,
    Form,
    Input,
    Table,
    message,
    Button,
    Checkbox,
    DatePicker,
    Spin
} from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import {
    useAddCardDetailsMutation,
    useDeleteCardDetailsMutation,
    useUpDateCardDetailsMutation,
} from "../../store/apiSlice";
import { useGetUserDetailQuery } from "../../store/getSlice";
import swal from 'sweetalert'
import EncryptionHelper from "../../Helpers/encryptionHelper";
import DecryptionHelper from "../../Helpers/decryptHelper";
import { MdOutlineRefresh } from "react-icons/md";
import PaginationOptionsHelper from "../../Helpers/paginationOptionsHelper";

const CreditCardInformation = () => {
    const [form] = Form.useForm();
    const [currentPage, setCurrentPage] = useState(1);
    const [searchText, setSearchText] = useState(null);
    const [pageSize, setPageSize] = useState(5);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [filteredCards, setFilteredCards] = useState([]);
    const [cardId, setCardId] = useState();
    const [messageApi, contextHolder] = message.useMessage();
    const [upDateCardDetails] = useUpDateCardDetailsMutation();
    const [deleteCardDetails] = useDeleteCardDetailsMutation()
    const [addCardDetails, { isLoading: addCardLoading }] = useAddCardDetailsMutation();
    const { data: userDetail, refetch, isLoading: userDetailsLoading } = useGetUserDetailQuery(`?page=${currentPage}&per_page=${pageSize}`);
    const [isSaving, setIsSaving] = useState(false)
    const [isEditSaving, setIsEditSaving] = useState(false)
    const [loader,setLoader] = useState(false)
    const [formType, setFormType] = useState();
    const success = (message) => {
        messageApi.open({
            type: "success",
            content: message,
        });
    };

    useEffect(() => {
        if (userDetail?.data?.cards) {
            if(userDetail.data.cards?.length <5){
                setPageSize(userDetail.data.cards?.length)
            }
            setFilteredCards(userDetail.data.cards);
        }
    }, [userDetail]);

    const errorMessage = (message) => {
        messageApi.open({
            type: "error",
            content: message,
        });
    };
    const encryptedCardNumber = (cardNumber) => {
        if (typeof cardNumber !== 'string' || cardNumber.length < 4) {
            return 'Invalid card number';
        }
        return cardNumber.slice(0, -4).replace(/\d/g, '*') + cardNumber.slice(-4);
    };
    const showModal = (cardData) => {
        setIsModalOpen(true);
        if (cardData) {
            const newCardData = { ...cardData };

            if (newCardData.exp_date) {
                const [month, year] = newCardData.exp_date.split('/');
                const formattedExpDate = `20${year}-${month.padStart(2, '0')}`;
                newCardData.exp_date = formattedExpDate;
            }

            form.setFieldsValue(newCardData);
        } else {
            form.resetFields();
        }
    };

    const pageSizeOptions = PaginationOptionsHelper(filteredCards.length);

    const fetchData = async() =>{
        setLoader(true)
        await refetch()
        setLoader(false)
    }

    useEffect(()=>{
        fetchData()
    },[pageSize,currentPage])


    const handleOk = () => {
        setIsModalOpen(false);
    };
    const validateExpiryDate = (_, value) => {
        if (!value) {
            return Promise.reject('Please enter the expiry date');
        }

        const date = new Date(value);
        const currentDate = new Date();
        const month = date.getMonth();
        const year = date.getFullYear();


        if (year < currentDate.getFullYear() || (year === currentDate.getFullYear() && month < currentDate.getMonth())) {
            return Promise.reject('The expiry date cannot be in the past');
        }


        return Promise.resolve();
    };

    const onFinish = async (values) => {
        const date = new Date(values.exp_date);
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString().slice(-2);
        const formattedDate = `${month}/${year}`;
        const stringifyData = JSON.stringify({
            name_on_card: values.name_on_card,
            credit_card_number: values.credit_card_number,
            cvv: values.cvv,
        })
        const encryptedCardDetails = EncryptionHelper(stringifyData);
        let encryptedData = {
            exp_date: formattedDate,
            card_details: encryptedCardDetails,
            default: values.default
        }
        if (formType === "Add") {
            setIsSaving(true)
            try {
                const res = await addCardDetails({data:encryptedData});
                if (res.data) {
                    success("Card Details added succesfully");
                    refetch();
                    form.resetFields();
                    setIsModalOpen(false);
                } else {
                    errorMessage(res.error.data.message);
                }
            } catch (error) {
                errorMessage("An unexpected error occurred");
            } finally {
                setIsSaving(false)
            }
        }

    };
    const handleConfirm = async (id) => {
        try {
            const res = await deleteCardDetails(id);
            if (res.data) {
                success(res.data.message);
                refetch();
            } else {
                errorMessage(res.error.data.message);
            }
        } catch (error) {
            errorMessage("An unexpected error occurred");
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };


    const columns = [
        {
            title: 'S.No.',
            dataIndex: 'serial',
            key: 'serial',
            render: (text, record, index) => {
                const startSerial = (currentPage - 1) * pageSize + 1;
                return startSerial + index;
            },
        },
        {
            title: "Full Name",
            render: (data) => (
                <>
                    {DecryptCardDetisl(data?.card_details)?.name_on_card}
                    {data?.default && (
                        <span style={{ color: '#1890ff', fontWeight: 'bold' }}> - Primary Card</span>
                    )}
                </>
            ),
        },
        {
            title: "Card Number",
            render: (data) => encryptedCardNumber(DecryptCardDetisl(data?.card_details)?.credit_card_number),
        },
        {
            title: "Expiration Date",
            render: (data) => `${data?.exp_date}`,
        },

        {
            title: "Options",
            render: (data) => (
                <div className="buttonWrap d-flex justify-content-center">
                    <button type="button" className="btn optionBtn"
                        onClick={() => {
                            swal({
                                title: "Warning",
                                text: "Are you sure you want to delete the card details?",
                                icon: "warning",
                                dangerMode: true,
                                buttons: {
                                    cancel: {
                                        text: "Cancel",
                                        value: null,
                                        visible: true,
                                        className: "swal-cancel-confirm-button",
                                        closeModal: true,
                                    },
                                    confirm: {
                                        text: "OK",
                                        value: true,
                                        className: "swal-custom-confirm-button",
                                        closeModal: true,
                                    }
                                },
                            }).then((ok) => {
                                if (ok) {
                                    handleConfirm(data?.id);
                                }
                            });
                        }}
                    >Delete</button>
                </div>
            ),
        },
    ];

    const handleSearchChange = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchText(value);
        setCurrentPage(1);

        if (userDetail?.data?.cards) {
            const filtered = userDetail.data.cards.filter(card => {
                const cardHolderName = DecryptCardDetisl(card.card_details)?.name_on_card.toLowerCase();
                const cardNumber = encryptedCardNumber(DecryptCardDetisl(card.card_details)?.credit_card_number).toLowerCase();
                const expDate = card.exp_date.toLowerCase();

                return (
                    cardHolderName.includes(value) ||
                    cardNumber.includes(value) ||
                    expDate.includes(value)
                );
            });
            if(userDetail?.data?.cards?.length <5){
                setPageSize(userDetail?.data?.cards?.length)
            }
            setFilteredCards(filtered);
        }
    };

    const DecryptCardDetisl = (encryptedCardDetails) => {
        try {
            const decryptedDetails = DecryptionHelper(encryptedCardDetails);
            if (decryptedDetails) {
                const parsedDetails = JSON.parse(decryptedDetails);
                return parsedDetails;
            } else {
                console.error("Decryption returned null or undefined");
                return null
            }
        } catch (error) {
            console.error("Error during decryption:", error);
        }
    }

    return (
        <div className="billingWrapper">
            <div className="card accountCard mt-4">
                {contextHolder}
                <div className="card-header">
                    <h4 className="card-title">Card Information</h4>
                    <div className="search-button-container d-flex justify-content-end align-items-center">
                        <Input.Search
                            placeholder="Search..."
                            allowClear
                            value={searchText}
                            onChange={handleSearchChange}
                            className=" tableSearchInput"
                        />
                        <button
                            type="button"
                            className="btn editButton ms-4"
                            style={{fontSize:"0.875rem", fontWeight:"400",color:"#000", backgroundColor:"#F8BD00", border:"1px solid #F8BD00", borderRadius:"8px", padding:"0.375rem 0.5rem", transition:"all 0.3s ease-in-out", minWidth: "5vw" }}
                            onClick={() => {
                                showModal();
                                setFormType("Add");
                            }}
                        >
                            Add Card
                        </button>
                    </div>
                </div>
                <div className="tableWrapper table-responsive table-pagination">
                    <Table
                        className="billingWrap"
                        dataSource={loader ? []:filteredCards.map(item => ({ ...item, key: item.id }))}
                        columns={columns}
                        pagination={{
                            total: filteredCards.length,
                            current: currentPage,
                            pageSize: pageSize,
                            onChange: (page, size) => {
                                setCurrentPage(page);
                                setPageSize(size);
                            },
                            pageSizeOptions: pageSizeOptions,
                            showSizeChanger: true,
                            locale: {
                                items_per_page: `/${filteredCards.length|| 0} Records`,
                            },
                        }}
                        loading={{
                            spinning: userDetailsLoading || loader,
                            indicator: <Spin indicator={<LoadingOutlined spin />} />,
                        }}
                    />
                </div>
            </div>
            <Modal
                title={`${formType} Card Details`}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleOk}
                className="formCard"
                centered
                okText="Update"
                footer={null}
            >
                <div className="modalBody">
                    <Form
                        form={form}
                        name="cardForm"
                        layout="vertical"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <div className="row">
                            <div className="col-md-12">
                                <Form.Item name="name_on_card" label="Full Name" rules={[
                                    { required: true, message: 'Please enter the cardholder’s name' },
                                    {
                                        pattern: /^[A-Za-z\s]+$/,
                                        message: 'Cardholder’s name can only contain letters and spaces',
                                    },
                                    {
                                        max: 25,
                                        message: 'Cardholder’s name cannot exceed 25 characters',
                                    },
                                ]}>
                                    <Input className="form-control" placeholder="Full Name" />
                                </Form.Item>
                            </div>
                            <div className="col-md-12">
                                <Form.Item name="credit_card_number" label="Card Number" rules={[
                                    { required: true, message: 'Please enter your card number' },
                                    { pattern: /^[0-9]{16}$/, message: 'Card number must be 16 digits' },
                                ]}>
                                    <Input className="form-control" placeholder="Card Number" maxLength={16} inputMode="numeric"
                                        onKeyPress={(e) => {
                                            if (!/[0-9]/.test(e.key)) {
                                                e.preventDefault();
                                            }
                                        }} />
                                </Form.Item>
                            </div>
                            <div className="col-md-4">
                                <Form.Item name="exp_date" label="Expiration Date" rules={[{ validator: validateExpiryDate }]}>
                                    <DatePicker
                                        picker="month"
                                        format="MM/YY"
                                        autoComplete="off"
                                        placeholder="MM/YY"
                                        className="form-control"
                                    />
                                </Form.Item>
                            </div>

                            <div className="col-md-4">
                                <Form.Item name="cvv" label="CVV" rules={[
                                    { required: true, message: 'Please enter the CVV' },
                                    { pattern: /^[0-9]{3,4}$/, message: 'CVV must be 3 or 4 digits' },
                                ]}>
                                    <Input.Password className="form-control w-100" placeholder="CVV" maxLength={4} />

                                </Form.Item>
                            </div>
                            <div className="col-md-4 pt-4 mt-1">
                                <Form.Item
                                    name="default"
                                    valuePropName="checked"
                                >
                                    <Checkbox>
                                        Primary Card
                                    </Checkbox>
                                </Form.Item>
                            </div>
                            <div className="buttonWrapper text-end">
                                <Button icon={<MdOutlineRefresh />} loading={addCardLoading} htmlType="submit" className="submitBtn me-4" disabled={formType === 'Add' ? isSaving : isEditSaving}>
                                    Save
                                </Button>
                                <button
                                    type="button"
                                    className="btn cancelBtn"
                                    onClick={handleOk}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </Form>
                </div>
            </Modal>

        </div>
    );
};

export default CreditCardInformation;
