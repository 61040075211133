import React, { useEffect } from "react";
import { Image } from "antd";
import { Link, NavLink, useLocation } from "react-router-dom";
import { Logo } from "../../constant/images";
import { SlLocationPin } from "react-icons/sl";
import { LuPhone } from "react-icons/lu";
import { TbMail } from "react-icons/tb";
import { FaLinkedinIn, FaFacebookF, FaInstagram } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { useLazyGetBlogListQuery } from "../../store/getSlice";
import { fileUrl } from "../../Helper";
import { useNavigate } from "react-router-dom";


const Footer = ({ menu }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const state = useSelector((state) => state?.persistedReducer?.webData)

    const [fetchBlogList, { data: getBlogList, isLoading, }] = useLazyGetBlogListQuery();

    const aboutUs = menu?.find(item =>
        item.title.toLowerCase().includes("about") // Adjust for dynamic title
    );
    const privacyPolicy = menu?.find(item =>
        item.title.toLowerCase().includes("privacy") // Adjust for dynamic title
    );
    const termsOfService = menu?.find(item =>
        item.title.toLowerCase().includes("terms") // Adjust for dynamic title
    );
    const homePage = menu?.find(item => item.display_order === 1);
    useEffect(() => {
        window.scrollTo(0, 0);

    }, [location.pathname]);
    useEffect(() => {
        fetchBlogList()
    }, [])
    const groupMenuItems = (menu) => {
        const groupedMenuItems = {};


        menu?.forEach((item) => {
            groupedMenuItems[item.id] = { ...item, children: [] };
        });

        menu?.forEach((item) => {
            if (item.parent_id && groupedMenuItems[item.parent_id]) {
                groupedMenuItems[item.parent_id].children.push(groupedMenuItems[item.id]);
            }
        });

        // Return only the top-level parents
        return Object.values(groupedMenuItems).filter((item) => item.parent_id === 0);
    };
    const groupedItems = groupMenuItems(menu);
    return (
        <div className="footerWraper">
            <div className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                            <div className="footerCol">
                                <div className="footerLogo" style={{cursor:"pointer"}} onClick={()=>  navigate('/')}>
                                    <img src={state?.logo ? `${fileUrl}storage/${state?.logo}` : Logo} className="img-fluid" alt="logo" />
                                </div>
                                {/* <p className="address"> */}
                                <a className="address" href={`http://maps.google.com/?q=${state?.address}`}>
                                    <SlLocationPin />
                                    {state?.address}
                                </a>
                                {/* </p> */}
                                {/* <p className="phone"> */}
                                <a className="phone" href={`tel:${state?.contact_number}`}>
                                    <LuPhone /> {state?.contact_number}
                                </a>
                                {/* <p className="email"> */}
                                <a className="email" href={`mailto:${state?.email}`}>
                                    <TbMail />
                                    {state?.email}
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footerCol">
                                <h4 className="footerColTitle">Quick Links</h4>
                                <div className="footerLinks">
                                    <ul className="footerMenuList">
                                        {groupedItems?.filter(item => item?.slug === "quick-links")[0]?.children.map((item) => {
                                            if (item?.alignment === "left" && item?.display_sections?.includes("footer")) {
                                                return (<li key={item.id} className="footerMenuItem">
                                                    <NavLink to={`/${item.permanent_slug} `}>
                                                        {item.title}
                                                    </NavLink>
                                                </li>)
                                            }
                                            return null
                                        })}
                                        {/* <li className="footerMenuItem">
                                            <Link to={`/homepage`}>{homePage?.title}</Link>
                                        </li>
                                        <li className="footerMenuItem">
                                            <Link to={"/about-us"}>{aboutUs?.title}</Link>
                                        </li> */}
                                        {/* <li className="footerMenuItem">
                                            <Link to={"/contact-us"}>Contact Us</Link>
                                        </li> */}

                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footerCol">
                                <h4 className="footerColTitle">Blogs</h4>
                                <div className="footerLinks">
                                    <ul className="footerMenuList">
                                        {getBlogList?.data?.slice(0, 5).map((item) => {
                                            return (
                                                <li className="footerMenuItem" key={item?.id}>
                                                    <NavLink to="/blog-details" state={{ data: item?.id }}>
                                                        {item?.title}
                                                    </NavLink>
                                                </li>
                                            );
                                        })}
                                    </ul>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="footerCol">
                                {/* <h4 className="footerColTitle mb-4">Subscribe to our Newsletter!</h4>
                                <div className="inpurSection">
                                    <Input placeholder="Enter email here" className="newsLetterInput" />
                                    <button type="button" className="btn sendBtn">
                                        <IoArrowForward />
                                    </button>
                                </div> */}
                                <h4 className="footerColTitle mb-2">Follow Us on</h4>
                                <div className="footerSocial">
                                    <Link to={state?.linkedin_link} target="_blank" rel="noopener noreferrer" className="socialLink">
                                        {state?.linkedin_logo ? <Image src={`${fileUrl}storage/${state?.linkedin_logo}`} className="socialLink" preview={false} /> : <FaLinkedinIn />}
                                    </Link>
                                    <Link to={state?.facebook_link} target="_blank" rel="noopener noreferrer" className="socialLink">
                                        {state?.facebook_logo ? <Image src={`${fileUrl}storage/${state?.facebook_logo}`} className="socialLink" preview={false} /> : <FaFacebookF />}
                                    </Link>
                                    <Link to={state?.instagram_link} target="_blank" rel="noopener noreferrer" className="socialLink">
                                        {state?.instagram_logo ? <Image src={`${fileUrl}storage/${state?.instagram_logo}`} className="socialLink" preview={false} /> : <FaInstagram />}
                                    </Link>
                                </div>
                                <div className="mt-2">
                                    {groupedItems.map((item) => {
                                        if (item?.alignment === "right" && item?.display_sections?.includes("footer")) {
                                            return (
                                                <NavLink to={`/${item.permanent_slug} `} style={{ textDecoration: 'none', color: 'black' }}>
                                                    {item.title}
                                                </NavLink>
                                            )
                                        }
                                        return null
                                    })}
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="copyWriteFooter">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 d-flex ml-4">
                            <p className="copyWrite">
                                ©Copyright – All rights reserved.
                            </p>
                            <ul className="d-flex" style={{listStyle:"none", gap:"1rem"}}>
                            {groupedItems?.filter(item => item?.display_sections?.includes("bottom"))?.map((item, index) => {
                                if (item?.alignment === "left" && item?.display_sections?.includes("bottom") && index < 5) {
                                    return (<li key={item.id} style={{ textDecoration: 'none',color:" #AAAAAA;" }} className="footerMenuItem footer-right-links">
                                        <NavLink to={`/${item.permanent_slug} `} style={{ textDecoration: 'none', color:"#AAAAAA"}}>
                                            {item.title}
                                        </NavLink>
                                    </li>)
                                }
                                return null
                            })}
                            </ul>
                        </div>
                        <div className="col-md-6">
                            <div className="footerMenu">
                                <div className="menuList">
                                    {groupedItems.map((item) => {
                                        if (item?.alignment === "right" && item?.display_sections?.includes("bottom")) {
                                            return (<Link key={item.id} to={`/${item.permanent_slug} `} className="footerMenuLink">{item?.title}</Link>)
                                        }
                                        return null
                                    })}
                                    {/* <Link to={"/terms-and-conditions"} className="footerMenuLink">{termsOfService?.title}</Link>
                                    <Link to={"/privacy-policy"} className="footerMenuLink">{privacyPolicy?.title}</Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
