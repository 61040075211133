import CryptoJS from "crypto-js";

export default function EncryptionHelper(cvv) {
    const secretKey = process.env.REACT_APP_SECRET_KEY;
    if (!secretKey) {
        console.error("Missing secret key!");
        return null;
    }
 
    const key = CryptoJS.SHA256(secretKey).toString(CryptoJS.enc.Hex);
    const parsedKey = CryptoJS.enc.Hex.parse(key);
 
    const iv = CryptoJS.lib.WordArray.random(16);
    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(cvv), parsedKey, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
 
    return CryptoJS.enc.Base64.stringify(iv.concat(encrypted.ciphertext));
}


