import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Survey } from 'survey-react';
import 'survey-core/defaultV2.min.css';
import './surveystyle.css';

const SurveyComponent = () => {
    const [surveyJson, setSurveyJson] = useState(null);

    useEffect(() => {
        axios.get('https://sunbird-staging.aleaspeaks.com/api/v1/convert-to-surveyJs', {
            params: {
                flow_id: 113
            }
        })
            .then((res) => {
                const modifiedJson = {
                    ...res.data,
                    showQuestionNumbers: false,
                };
                if (modifiedJson?.pages?.length > 0 && modifiedJson.pages[0]?.elements?.length > 0) {
                    modifiedJson.pages[0].elements[0].elements.forEach(element => {
                        
                        if (element?.title === "Zip Code") {
                            element.inputType = 'number';
                        }
                    });
                }
                
                setSurveyJson(modifiedJson);
            })
            .catch((err) => {
                console.error(err);
            });
    }, []);

    const onComplete = (survey) => {
        const answerData = Object.keys(survey.data).map((key) => {
            const question = survey.getQuestionByName(key);

            // Check if the question is a dropdown and get the corresponding text label
            let answer = survey.data[key];
            if (question.getType() === "dropdown") {
                const choice = question.choices.find(choice => choice.value === answer);
                answer = choice ? choice.text : answer;
            }

            return {
                field_name: question.title,
                answer: answer,
                question_id: question?.jsonObj?.question_id,
            };
        });

        const payload = {
            flow_id: 113,
            answerData: answerData
        };

        axios.post('https://sunbird-staging.aleaspeaks.com/api/v1/session-bulk', payload)
            .then((res) => {
            })
            .catch((err) => {
                console.error("Error sending survey results:", err);
            });
    };

    // This function gets called after each question is rendered
    const onAfterRenderQuestion = (survey, options) => {
        // Check if the current question is the one that should display an input type="time"
        if (options.question.type === "time") {
            const questionElement = options.htmlElement;
            const inputField = questionElement.querySelector('input');

            // Modify or customize the input field here, e.g., adding a placeholder or default value
            if (inputField) {
                inputField.setAttribute('placeholder', 'Select time');
                inputField.setAttribute('min', '09:00');
                inputField.setAttribute('max', '18:00');
            }
        }
    };


    return (
        <div className='detailSurvey'>
            {surveyJson ? (
                <Survey
                    json={surveyJson}
                    onComplete={onComplete}
                    onAfterRenderQuestion={onAfterRenderQuestion}
                />
            ) : (
                <p>Loading survey...</p>
            )}
        </div>
    );
};

export default SurveyComponent;
