import React, { useEffect, useState } from "react";
import HelpSection from "./help";
import RecentSection from "./recentSection";
import { useRecentlyViewPropertiesMutation, useRemoveFavouriteMutation } from "../../store/apiSlice";
import { useFavouritePropertyQuery } from "../../store/getSlice";
import { message, Spin, Pagination } from "antd";
import { FaHeart, FaLocationDot } from "react-icons/fa6";
import { SliderImageThree } from "../../constant/images";
import { TbBed, TbVector } from "react-icons/tb";
import { LuBath } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { LoadingOutlined } from '@ant-design/icons';

const MyHomes = () => {
    const navigate = useNavigate()
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedProperty, setSelectedProperty] = useState(null);
    const [pageSize, setPageSize] = useState(5);
    const [addViewProperty, { isLoading }] = useRecentlyViewPropertiesMutation();
    const [removeFavourite] = useRemoveFavouriteMutation();
    const [propertyList, setPropertyList] = useState('');
    const [isRemoveSaving, setIsRemoveSaving] = useState(false)
    const { data: getFavouriteProperties, refetch, isLoading: isLoadingFavourite } = useFavouritePropertyQuery(`?page=${currentPage}&per_page=${pageSize}`);
    const [messageApi, contextHolder] = message.useMessage();
    const success = (message) => {
        messageApi.open({
            type: "success",
            content: message,
        });
    };
    const errorMessage = (message) => {
        messageApi.open({
            type: "error",
            content: message,
        });
    };

    const deletefavourite = async (id) => {
        setIsRemoveSaving(true)
        try {
            const res = await removeFavourite(id);
            if (res.data) {
                success("Property remove successfully");
                refetch()
            } else {
                errorMessage(res.error.data.message);
            }
        } catch {
            errorMessage("An unexpected error occurred");
        } finally {
            setIsRemoveSaving(false)
        }
    };

    const viewProperty = async (data) => {
        setSelectedProperty(data)
        let value = {
            property_id: data,
        };

        try {
            const res = await addViewProperty(value);
            if (res.data) {
                navigate("/property-details", {
                    state: {
                        id: data
                    }
                });
            } else {
                errorMessage(res.error.data.message);
            }
        } catch (error) {
            errorMessage("An unexpected error occurred");
        }finally{
            setSelectedProperty(null)
        }
    };

    useEffect(() => {
        if (getFavouriteProperties?.data?.length) {
            setPropertyList(getFavouriteProperties?.data);
        } else {
            setPropertyList('')
        }
    }, [getFavouriteProperties]);

    return (
        <div className="propertyWrapper favoriteWrapper">

            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="titleSection">
                            <h5 className="topTitle">My Homes</h5>
                            <h3 className="mainTitle">Favorite Homes</h3>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="propertyCardsContainer">
                            {isLoadingFavourite ? (
                                <Spin indicator={<LoadingOutlined spin />} style={{ display: "flex", alignItems: "center", justifyContent: "center" }} size="large" />
                            ) : propertyList?.length ? (
                                <>
                                    {propertyList?.map((property, index) => (
                                        <div className="card propertyCard" key={index}>
                                            <div className="card-body">
                                                {contextHolder}
                                                <div className="propertyContent">
                                                    <div className="propertyImage">
                                                        <img
                                                            onClick={() => viewProperty(property?.property?.listing_key)}
                                                            src={SliderImageThree}
                                                            className="img-fluid"
                                                            alt="property"
                                                        />
                                                        <p className="wishlist">
                                                            <FaHeart
                                                                onClick={() => {
                                                                    if (!isRemoveSaving) {
                                                                        deletefavourite(property?.property?.listing_key);
                                                                    }
                                                                }}
                                                            />
                                                        </p>
                                                    </div>
                                                    <div className="propertyDetails" onClick={() => viewProperty(property?.property?.listing_key)}>
                                                        <h4 className="name">{property?.property?.unparsed_address}</h4>
                                                        <p className="location">
                                                            <FaLocationDot />{" "}
                                                            {
                                                                `${property?.property?.postal_city}, ${property?.property?.state_or_province}, ${property?.property?.country}-${property?.property?.postal_code}`
                                                            }
                                                        </p>
                                                        <div className="amenities">
                                                            <span className="beds">
                                                                <TbBed /> {property?.property?.bedrooms_total} Beds
                                                            </span>
                                                            <span className="bath">
                                                                <LuBath /> {property?.property?.bathrooms_total} Baths
                                                            </span>
                                                            <span className="bath">
                                                                <TbVector /> {property?.property?.lot_size} Sqft
                                                            </span>
                                                        </div>
                                                        <div className="bottomDetails">
                                                            <span className="dtl">{property?.property?.property_type}</span>
                                                            <span className="divider"></span>
                                                            <span className="dtl">{property?.property?.property_subtype}</span>
                                                        </div>
                                                    </div>
                                                    <span className="divider"></span>
                                                    <div className="propertyPrice" onClick={() => viewProperty(property?.property?.listing_key)}>
                                                        <div className="price">
                                                            <h4>
                                                                {property?.property?.price
                                                                    ? property.property.price.toLocaleString('en-US', {
                                                                        style: 'currency',
                                                                        currency: 'USD',
                                                                    })
                                                                    : '$0.00'}
                                                            </h4>
                                                        </div>
                                                        <div className="buttonWrap">
                                                            <button
                                                                type="button"
                                                                className="btn detailBtn"
                                                                onClick={() => viewProperty(property?.property?.listing_key)}
                                                            >
                                                                {isLoading && (selectedProperty === property?.property?.listing_key) ? 'Loading...' : 'Details'}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    <div className="d-flex justify-content-center align-items-center">
                                        <Pagination
                                            current={currentPage}
                                            pageSize={pageSize}
                                            total={getFavouriteProperties?.meta?.total}
                                            onChange={(page, size) => {
                                                setCurrentPage(page);
                                                setPageSize(size);
                                            }}
                                        />
                                    </div>
                                </>
                            ) : (
                                <p>No data found</p>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <RecentSection />
                        <HelpSection />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyHomes;