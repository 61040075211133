import React, { useEffect, useState } from "react";
import { Form, Input, Select } from "antd";
import { Link } from "react-router-dom";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const CalculatePayment = ({ amount, type }) => {
    const [form] = Form.useForm();
    const [monthlyPayment, setMonthlyPayment] = useState(null);
    const [mortgagecalculation, setMortgageCalculation] = useState(null);
    const [purchasePrice, setPurchasePrice] = useState(amount);
    const [downPayment, setDownPayment] = useState(0);
    const [downPaymentPercent, setDownPaymentPercent] = useState(20);


    const calculateDownPaymentPercent = (dp, price) => {
        if (price && dp) {
            const percent = ((dp / price) * 100).toFixed(2);

            setDownPaymentPercent(percent);
        } else {
            setDownPaymentPercent(0);
        }
    };

    const onPriceChange = (e) => {
        const price = parseFloat(e.target.value);
        setPurchasePrice(price);
    };

    const onDownPaymentChange = (e) => {
        const dp = parseFloat(e.target.value);
        setDownPayment(dp);
        calculateDownPaymentPercent(dp, purchasePrice);
    };

    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const calculateMortgage = (values) => {
        const purchasePrice = parseFloat(values.price);

        const downPayment = parseFloat(values.dp);

        const loanAmount = purchasePrice - downPayment;

        const loanTerm = parseInt(values.loan_type);

        const monthlyInterestRate = values.annualInterestRate / 100 / 12;

        const numberOfPayments = loanTerm * 12;

        // Mortgage calculation formula

        const monthlyPayment =
            (loanAmount *
                monthlyInterestRate *
                Math.pow(1 + monthlyInterestRate, numberOfPayments)) /
            (Math.pow(1 + monthlyInterestRate, numberOfPayments) - 1);

        const totalPayableInterest = (monthlyPayment * numberOfPayments) - loanAmount;

        const firstMonthInterest = loanAmount * monthlyInterestRate;
        const firstMonthPrincipal = monthlyPayment - firstMonthInterest;

        setMonthlyPayment({
            total: monthlyPayment.toFixed(2),

            principal: firstMonthPrincipal.toFixed(2),

            interest: firstMonthInterest.toFixed(2),
        });
        setMortgageCalculation({
            total: (monthlyPayment * numberOfPayments).toFixed(2),

            principal: loanAmount.toFixed(2),

            interest: totalPayableInterest.toFixed(2),
        })
    };

    const onFinish = (values) => {
        calculateMortgage(values);
    };

    const chartOptions = {
        chart: {
            type: "pie",
        },

        title: {
            text: "Principal vs Interest Breakdown",
        },

        plotOptions: {
            pie: {
                innerSize: "70%",
                dataLabels: {
                    enabled: true,
                    format: "{point.name}: ${point.y:.1f}",
                },
            },
        },

        series: [
            {
                name: "Payment Breakdown",
                data: [
                    {
                        name: "Principal",
                        y: mortgagecalculation ? (parseFloat(mortgagecalculation.principal)) : 0,
                    },

                    {
                        name: "Interest",
                        y: mortgagecalculation ? parseFloat(mortgagecalculation.interest) : 0,
                    },
                ],
            },
        ],
    };

    useEffect(() => {
        if (amount !== undefined && type !== 'Leased') {
            let downpaymentAmount = amount / 100 * 20;

            form.setFieldsValue({
                price: amount,
                dp: downpaymentAmount.toFixed(2),
                loan_type: '30',
                annualInterestRate: '6.64'
            });

            let values = {
                price: amount,
                dp: downpaymentAmount.toFixed(2),
                loan_type: '30',
                annualInterestRate: '6.64'
            }
            calculateMortgage(values);
        }
    }, [amount, form]);

    // Custom validator for numeric input
    const numberValidator = (_, value) => {
        if (!value || /^[0-9]*\.?[0-9]*$/.test(value)) {
            return Promise.resolve();
        }
        return Promise.reject(new Error("Only numbers are allowed"));
    };

    // Custom validator to ensure Down Payment is not greater than Purchase Price
    const downPaymentValidator = (_, value) => {
        if (value > purchasePrice) {
            return Promise.reject(new Error("Down Payment cannot be greater than Purchase Price"));
        } else if (value === purchasePrice) {

        }
        return Promise.resolve();
    };

    const interestRateValidator = (_, value) => {
        if (parseInt(value) === 0) {
            return Promise.reject(new Error("Annual Interest Rate cannot be zero"));
        }
        return Promise.resolve();
    };

    return (
        <div className="calculateWrapper">
            <div className="innerWrap">
                <p className="topText">Mortgage Calculator</p>
                <p className="subText">
                    For traditional mortgage, enter your home loan details and get
                    personalized quotes with today’s mortgage rates.
                </p>

                <div className="formWrapper">
                    <Form
                        name="PaymentForm"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        layout="vertical"
                    >
                        <div className="row">
                            <div className="col-md-6">
                                <Form.Item
                                    label="Purchase Price"
                                    name="price"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter the purchase price",
                                        },
                                        {
                                            validator: numberValidator, // Custom number validation
                                        },
                                    ]}
                                >
                                    <Input placeholder="$ 205,900" className="form-control" onChange={onPriceChange} />
                                </Form.Item>
                            </div>

                            <div className="col-md-6">
                                <Form.Item
                                    label="Down Payment"
                                    name="dp"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter your down payment",
                                        },
                                        {
                                            validator: numberValidator, // Custom number validation
                                        },
                                        {
                                            validator: downPaymentValidator, // Custom Down Payment Validation
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="$ 205,900"
                                        className="form-control-addon"
                                        addonAfter={`${downPaymentPercent}%`}
                                        onChange={onDownPaymentChange}
                                    />
                                </Form.Item>
                            </div>

                            <div className="col-md-6">
                                <Form.Item
                                    label="Loan Tenure"
                                    name="loan_type"
                                    rules={[
                                        {
                                            required: true,

                                            message: "Select the loan tenure",
                                        },
                                    ]}
                                >
                                    <Select
                                        className="form-control-check"
                                        onChange={handleChange}
                                        placeholder="Select loan tenure"
                                    >
                                        <Select.Option value="10">10 Year Fixed</Select.Option>
                                        <Select.Option value="15">15 Year Fixed</Select.Option>
                                        <Select.Option value="20">20 Year Fixed</Select.Option>
                                        <Select.Option value="30">30 Year Fixed</Select.Option>
                                    </Select>
                                </Form.Item>
                            </div>

                            <div className="col-md-6">
                                <Form.Item
                                    label="Annual Interest Rate"
                                    name="annualInterestRate"
                                    rules={[
                                        {
                                            required: true,

                                            message: "Please input your annual interest rate",
                                        },
                                        {
                                            validator: numberValidator, // Custom number validation
                                        },
                                        {
                                            validator: interestRateValidator, // Custom validation for interest rate
                                        },
                                    ]}
                                >
                                    <Input placeholder="e.g. 5.0" className="form-control" />
                                </Form.Item>
                            </div>

                            <div className="col-md-12 text-center my-5">
                                <button type="submit" className="btn accountBtn w-auto">
                                    Calculate
                                </button>
                            </div>

                            {monthlyPayment && (
                                <div className="result">
                                    <p>
                                        Estimated Monthly Payment:{" "}
                                        <strong>${monthlyPayment.total}</strong>
                                    </p>

                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={chartOptions}
                                    />
                                </div>
                            )}
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default CalculatePayment;
