import React, { useState } from "react";
import CTA from "../../components/cta/cta";
import BlogDetails from "./blogDetails";
import EbookSection from "./ebook";
import AdsSection from "../property/ads";
import {useLocation } from "react-router-dom";

const SingleBlog = () => {
    const location = useLocation();
    const [blogId, setBlogId] = useState()
    return (
        <div className="singleBlogWrapper">
            <div className="pageHeader">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="pageHeading">
                                <h3>{location?.state?.blogTitle}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="innerWrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-8">
                            <BlogDetails setBlogId={setBlogId} />
                        </div>
                        <div className="col-lg-4 col-md-4">
                            {blogId ? (
                                <AdsSection blogId={blogId} />
                            ) : (
                                <></>
                            )}

                        </div>
                    </div>
                </div>
            </div>
            <EbookSection className={'bgGrey ebookWrapper'} />
            <CTA />
        </div>
    );
};

export default SingleBlog;