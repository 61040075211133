import { createApi, } from "@reduxjs/toolkit/query/react";
import { baseQueryWithReauth } from "../Helpers/authHelper";


export const getApi = createApi({
    reducerPath: "getApi",
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getNearProperty: builder.query({
            query: (page) => {
                if (page)
                    return {
                        url: `get-property-near-me?page=${page}`,
                        method: "GET",
                    }
            },
            providesTags: ["nearProperty"],
        }),
        getRecentlyProperty: builder.query({
            query: () => ({
                url: "recently-viewed-properties",
                method: "GET",
            }),
            providesTags: ["RecentlyProperty"],
        }),
        favouriteProperty: builder.query({
            query: (query) => ({
                url: `favorite_property${query}`,
                method: "GET",
            }),
            providesTags: ["FavouriteProperty"],
        }),
        getUserDetail: builder.query({
            query: (query) => ({
                url: `user-detail${query}`,
                method: "GET",
            }),
            providesTags: ["UserDetail"],
        }),
        getPropertyDetails : builder.mutation({
            query: (id) => ({
                url:`property/${id}`,
                method: "GET",
            }),
            providesTags: ["PropertyDetails"],
        }),
        getLocation : builder.query({
            query: (nearMe) => {
                if(nearMe)
                return{
                    url:`get-ip`,
                    method: "GET",
                }
               
            },
            providesTags: ["IpAddress"],
        }),
        getRegisterUserDetails: builder.query({
            query: (token) => {
                if (token) {
                    return {
                        url: `user-info/${token}`,
                        method: "GET"
                    };
                }
               
            },
            providesTags: ["RegisterUserDetails"],
        }),
      
        getTransaction : builder.query({
            query: (query) => ({
                url:`transactions${query}`,
                method: "GET",
            }),
            providesTags: ["Transaction"],
        }),
        getBlogList: builder.query({
            query: (currentPage) => {
                const url = currentPage ? `blogs?page=${currentPage}` : `blogs`; // Conditional URL
                return {
                    url,
                    method: "GET",
                };
            },
            providesTags: ["BlogList"],
        }),
        getBlogDetails : builder.query({
            query: (id) => ({
                url:`blogs/${id}`,
                method: "GET",
            }),
            providesTags: ["BlogDetails"],
        }),
        getRetryPayment : builder.query({
            query: () => {
              
                    return{
                        url:`user/retry_payment`,
                        method: "GET",
                    }
              
            },
            providesTags: ["RetryPayment"],
        }),
         getFeaturedProperty: builder.query({
            query: () => {
                    return{
                        url:`get-featured-properties`,
                        method: "GET",
                    }                
            },
            providesTags: ["FeaturedProperty"],
        }),
        getRecentSearch: builder.query({
            query: () => {
                    return{
                        url:`recent-search`,
                        method: "GET",
                    }                
            },
            providesTags: ["RecentSearch"],
        }),
        dynamicNavbar: builder.query({
            query: () => {
                    return{
                        url:`navbar`,
                        method: "GET",
                    }                
            },
            providesTags: ["Navbar"],
          }),
          getSurveyStatus: builder.query({
            query: () => {
                    return{
                        url:`survey`,
                        method: "GET",
                    }                
            },
            providesTags: ["ChangeSurveyStatus"],
          }),
      
          getResendEmail : builder.query({
            query: () => {
                    return {
                        url: `user/resend-verification-email`,
                        method: "GET",
                    };
            },
            providesTags: ["Resend Email"],   
        }),
        getBlogProducts : builder.query({
            query: (blogId) => {
                    return {
                        url: `blogs/${blogId}/products`,
                        method: "GET",
                    };
            },
            providesTags: ["Blog Products"],   
        }),
        getEBooksListing : builder.query({
            query: (query) => {
                    return {
                        url: `ebooks${query}`,
                        method: "GET",
                    };
            },
            providesTags: ["e-Books"],   
        }),
        getPurchasedEBooksListing : builder.query({
            query: (query) => {
                    return {
                        url: `get-purchased-ebook${query}`,
                        method: "GET",
                    };
            },
            providesTags: ["purchased-e-Books"],   
        }),
        getBookCategories : builder.query({
            query: (query) => {
                    return {
                        url: `categories`,
                        method: "GET",
                    };
            },
            providesTags: ["book-cateories"],   
        }),
    }),

    tagTypes: ["nearProperty", "RecentlyProperty", "FavouriteProperty", "UserDetail","PropertyDetails","IpAddress","RegisterUserDetails","Transaction","BlogList","BlogDetails","RetryPayment","FeaturedProperty","RecentSearch","Navbar","ChangeSurveyStatus","Resend Email","Blog Products","e-Books","purchased-e-Books", "book-cateories"]
});


export const { useGetNearPropertyQuery, useGetRecentlyPropertyQuery, useFavouritePropertyQuery, useGetUserDetailQuery,useGetPropertyDetailsMutation,useGetLocationQuery,useGetRegisterUserDetailsQuery,useGetTransactionQuery,useGetBlogDetailsQuery,useLazyGetBlogListQuery ,useLazyGetRetryPaymentQuery,useGetFeaturedPropertyQuery,useGetRecentSearchQuery,useDynamicNavbarQuery,useGetSurveyStatusQuery,useLazyGetResendEmailQuery, useGetBlogProductsQuery, useGetEBooksListingQuery, useGetPurchasedEBooksListingQuery, useGetBookCategoriesQuery} = getApi;
