import React, { useState } from "react";
import { Image, Upload, message, Button, Form, Input, Spin } from "antd";
import { UserAvatar } from "../../constant/images";
import { GoPencil } from "react-icons/go";
import {
	useChangePasswordMutation,
	useDeleteAccountMutation,
} from "../../store/apiSlice";
import { useGetUserDetailQuery } from "../../store/getSlice";
import { useUpdateProfileMutation } from "../../store/userUpdateSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { currentAddress, logout } from "../../store/authSlice";
import swal from "sweetalert";
import { LoadingOutlined } from '@ant-design/icons';

const LoginInformation = () => {
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [isEditing, setIsEditing] = useState(false);
	const [isEditingName, setIsEditingName] = useState(false);
	const [previewImage, setPreviewImage] = useState(null);
	const [isSaving, setIsSaving] = useState(false);
	const [changePassword] = useChangePasswordMutation();
	const [updateProfile] = useUpdateProfileMutation();
	const [deleteUserAccount] = useDeleteAccountMutation();
	const { data: userDetail, refetch, isLoading: isUserDetailsLoading } = useGetUserDetailQuery("");
	const [messageApi, contextHolder] = message.useMessage();

	const success = (message) => {
		messageApi.open({
			type: "success",

			content: message,
		});
	};

	const errorMessage = (message) => {
		messageApi.open({
			type: "error",
			content: message,
		});
	};

	const showPasswordFields = () => {
		setIsEditing(true);
	};

	const hidePasswordFields = async () => {
		setIsEditing(false);
	};

	const handleSaveName = async (value) => {
		setIsSaving(true);
		let data = new FormData();

		data.append("profile", value?.profile?.originFileObj);
		data.append("name", value?.name);

		try {
			const res = await updateProfile(data);
			if (res) {
				refetch()
				success(res.data.message);
				setIsEditingName(false);
			} else {
				errorMessage(res.error.data.message);
			}
		} catch (error) {
			errorMessage("An unexpected error occurred");
		} finally {
			setIsSaving(false);
		}
	};

	const onFinish = async (values) => {
		setIsSaving(true);
		try {
			const res = await changePassword(values);
			if (res.data) {
				success(res.data.message);
			} else {
				errorMessage(res.error.data.message);
			}
		} catch (error) {
			console.log(error)
		} finally {
			setIsSaving(false);
		}
	};
	const deleteAccount = async () => {
		setIsSaving(true);
		try {
			const res = await deleteUserAccount();
			if (res.data) {
				dispatch(logout());
				dispatch(currentAddress(null));
				localStorage.clear();
				navigate("/login");
			} else {
				errorMessage(res.error.data.message);
			}
		} catch (error) {
			errorMessage("An unexpected error occurred");
		} finally {
			setIsSaving(false);
		}
	};

	const onFinishFailed = (errorInfo) => {
		console.log("Failed:", errorInfo);
	};

	const saveValue = () => {
		if (userDetail?.data?.name) {
			form.setFieldsValue({ name: userDetail?.data?.name });
			form.setFieldsValue({
				profile: `https://rainfrog-api.aleaspeaks.com${userDetail?.data?.profile}`,
			});
		} else {
			form.resetFields();
		}
	};

	const handlePreview = (file) => {
		const reader = new FileReader();
		reader.onload = (e) => {
			setPreviewImage(e.target.result); // Set the preview image
		};
		reader.readAsDataURL(file);
	};


	const beforeUpload = (file) => {
		const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
		if (!isJpgOrPng) {
			errorMessage("You can only upload JPG/PNG files!");
			return false; // Prevent upload
		}

		// Prevent GIF uploads
		const isGif = file.type === "image/gif";
		if (isGif) {
			errorMessage("GIF files are not allowed!");
			return false; // Prevent upload
		}

		// Prevent PDF uploads
		const isPdf = file.type === "application/pdf";
		if (isPdf) {
			errorMessage("PDF files are not allowed!");
			return false; // Prevent upload
		}

		// Ensure the file size is less than 2MB
		const isLt2M = file.size / 1024 / 1024 < 2;
		if (!isLt2M) {
			errorMessage("Image must be smaller than 2MB!");
			return false; // Prevent upload
		}

		handlePreview(file);
		return false;
	};

	return (
		<div className="card accountCard loginCard">
			{!isUserDetailsLoading ? (
				<>
					<div className="card-body">
						{contextHolder}
						<div className="userProfile">
							<div className="userImageSection">
								<Image
									src={
										previewImage ? previewImage : userDetail?.data?.profile
											? `https://rainfrog-api.aleaspeaks.com/${userDetail?.data?.profile}`
											: UserAvatar
									}
									className="img-fluid user-profile-image mb-5"
									alt="Avatar"
									preview={false}
								/>
								<div className="userNameRow">
									{!isEditingName ? (
										<>
											<h3 className="userName">{userDetail?.data?.name}</h3>
											<button
												type="button"
												className="btn pencilBtn"
												onClick={() => {
													setIsEditingName(true);
													saveValue();
												}}
											>
												<GoPencil />
											</button>
										</>
									) : (
										<div className="nameEditWrapper">
											<Form
												form={form}
												name="userForm"
												layout="vertical"
												onFinish={handleSaveName}
											>
												<Form.Item
													name="profile"
													valuePropName="file"
													key={"profile"}
													getValueFromEvent={(e) => e && e.fileList && e.fileList[0]}
													className="profilePencilIcon"
												>
													<Upload
														listType="picture"
														className="uploadBtn"
														action={""}
														maxCount={1}
														beforeUpload={beforeUpload}
														showUploadList={false}

													>
														<Button icon={<GoPencil />} className="profileEdit"></Button>
													</Upload>

												</Form.Item>

												<Form.Item
													name="name"
													label="Full Name"
													rules={[
														{ required: true, message: "Please enter the  name" },
														{
															pattern: /^[A-Za-z\s]+$/,
															message: "Name can only contain letters and spaces",
														},
														{
															max: 25,
															message: "Name cannot exceed 25 characters",
														},
													]}
												>
													<Input
														className="form-control"
														placeholder="Enter new name"
													/>
												</Form.Item>

												<Form.Item>
													<div className="my-4">
														<button
															className="btn submitBtn me-4"
															htmltype="submit"
															disabled={isSaving}
														>
															Save
														</button>
														<button
															onClick={() => setIsEditingName(false)}
															className="btn cancelBtn"
														>
															Cancel
														</button>
													</div>
												</Form.Item>
											</Form>
										</div>
									)}
								</div>
							</div>

						</div>
					</div>
					<div className="card-header">
						<h4 className="card-title">Login Information</h4>
					</div>
					<div className="card-body">
						<div className="userInforWrap">
							<div className="mb-3">
								<label>Email</label>
								<p className="userInfo">{userDetail?.data?.email}</p>
							</div>
							{!isEditing ? (
								<div className="showHideDiv">
									<div className="mb-3">
										<label>Password</label>
										<p className="userInfo">********</p>
									</div>
									<div className="mb-3">
										<button
											type="button"
											className="btn editFormBtn"
											onClick={showPasswordFields}
										>
											Edit
										</button>
									</div>
								</div>
							) : (
								<div className="passwordInputsRow">
									{contextHolder}
									<Form
										layout="vertical"
										name="passwordForm"
										onFinish={onFinish}
										onFinishFailed={onFinishFailed}
									>
										<Form.Item
											label="Current Password"
											name="current_password"
											rules={[
												{
													required: true,
													message: "Please input your current  password!",
												},
											]}
										>
											<Input.Password
												className="form-control"
												placeholder="Enter password here"
											/>
										</Form.Item>

										<Form.Item
											label="New Password"
											name="new_password"
											rules={[
												{
													required: true,
													message: "Please input your password!",
												},
												{
													pattern:
														/^(?!.*\s)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
													message:
														"Password must be minimum 8 characters, include at least one uppercase letter, one lowercase letter, one number, and one special character.",
												},
											]}
										>
											<Input.Password
												className="form-control"
												placeholder="Enter password here"
											/>
										</Form.Item>

										<Form.Item
											label="Confirm Password"
											name="new_password_confirmation"
											dependencies={["password"]}
											rules={[
												{
													required: true,
													message: "Please input your Confirm password!",
												},
												({ getFieldValue }) => ({
													validator(rule, value) {
														if (!value || getFieldValue("new_password") === value) {
															return Promise.resolve();
														}
														return Promise.reject(
															"The passwords that you entered do not match!"
														);
													},
												}),
											]}
										>
											<Input.Password
												className="form-control"
												placeholder="Enter password here"
											/>
										</Form.Item>
										<div className="buttonWrapper">
											<Button
												htmlType="submit"
												className="submitBtn me-4"
												disabled={isSaving}
											>
												Save
											</Button>
											<button
												type="button"
												className="btn cancelBtn"
												onClick={hidePasswordFields}
											>
												Cancel
											</button>
										</div>
									</Form>
								</div>
							)}
						</div>
					</div>


					<div className="card-header border-bottom-0">
						<button
							className="card-title-button"
							onClick={() => {
								swal({
									title: "Warning",
									text: "Are you sure you want to cancel your account?",
									icon: "warning",
									dangerMode: true,
									buttons: ["Cancel", "OK"], // Adds a Cancel button
								}).then((ok) => {
									if (ok) {
										deleteAccount();
									}
								});
							}}
						>
							Cancel Account
						</button>
					</div>
				</>
			) : (<div className="d-flex justify-content-center align-items-center h-100"><Spin indicator={<LoadingOutlined spin />} /></div>)}
		</div>
	);
};

export default LoginInformation;
