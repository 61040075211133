import React, { useEffect } from "react";
import { Spin } from "antd";
import { useLocation } from "react-router-dom";
import { useGetPagesDetailQuery } from "../../store/loginRegisterSlice";
import { LoadingOutlined } from '@ant-design/icons';

const CmsPages = () => {
    const location = useLocation();
    const pathname = location.pathname;
    const cleanPathname = pathname.slice(1);
    const { data: pageDetails, isLoading } = useGetPagesDetailQuery(cleanPathname);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <>
            {isLoading ? (
                <div style={{height:"600px"}} className="d-flex justify-content-center align-items-center">
                <Spin
                    indicator={<LoadingOutlined spin />}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                    size="large"
                />
                </div>
            ) : (
                <div className="singleBlogWrapper">
                    <div className="innerWrapper">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <div className="card detailsCard blogCardSection">
                                        <div className="card-body">
                                            <div className="blogContent">
                                                <p className="title text-capitalize">
                                                    {" "}
                                                    {pageDetails?.page?.keywords}
                                                </p>
                                                <p className="text">{pageDetails?.page?.summary}</p>
                                                <h3 className="blogTitle">{pageDetails?.page?.title}</h3>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: pageDetails?.page?.description,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default CmsPages;
