import React, { useState, useEffect, useRef } from "react";
import { useNavigate, NavLink, Link, useLocation } from "react-router-dom";
import { FaRegUserCircle } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { currentAddress, logout } from "../../store/authSlice";
import { useGetUserDetailQuery } from "../../store/getSlice";
import { Image, Menu } from "antd";
import { fileUrl } from "../../Helper";

const   Navbar = ({ menuItems }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { data: userDetail, refetch } = useGetUserDetailQuery("");
    const [openDropdowns, setOpenDropdowns] = useState({});
    const [showMore, setShowMore] = useState(false);
    const dropdownRef = useRef(null);
    const [activeMore, setActiveMore] = useState(false);

    const handleAccountNavigate = () => navigate('/my-account');
    const handleProfileNavigate = () => navigate('/my-profile');

    const handleLogout = () => {
        setActiveMore(false);
        setShowMore(false);
        dispatch(logout());
        dispatch(currentAddress(null));
        localStorage.clear();
        navigate("/login");
    };

    const handleItemClick = (menuId) => {
        setOpenDropdowns((prev) => ({
            ...prev,
            [menuId]: !prev[menuId]
        }));
    };

    useEffect(() => {
        if (!menuItems) handleLogout();
    }, [menuItems]);

    useEffect(() => {
        refetch();
    }, []);

    const groupMenuItems = (menuItems) => {
        const groupedMenuItems = {};
        menuItems?.forEach((item) => {
            groupedMenuItems[item.id] = { ...item, children: [] };
        });
        menuItems?.forEach((item) => {
            if (item.parent_id && groupedMenuItems[item.parent_id]) {
                groupedMenuItems[item.parent_id].children.push(groupedMenuItems[item.id]);
            }
        });
        return Object.values(groupedMenuItems).filter((item) => item.parent_id === 0);
    };

    const groupedItems = groupMenuItems(menuItems);
    const isBlogActive = location.pathname === '/blogs' || location.pathname.startsWith('/blog-details');

    let pathName = location.pathname;
    pathName==="/e-book" ? pathName = "resources": pathName=pathName;

    const moreActiveMenu = location.pathname+location.search

    const handleMoreClick = () => {
        setShowMore(!showMore);
        setActiveMore(!activeMore);
    };

    return (
        <nav className="navbar navbar-expand-lg customNavbar">
            <div className="container">
                <ul className="dflex mobileShow w-100 ps-0 justify-content-between align-items-center">
                    <li className="nav-item dropdown">
                        <NavLink className="headerBtn dropdown-toggle dropdownBtn" role="button" data-bs-toggle="dropdown">
                            <FaRegUserCircle />
                        </NavLink>
                        <ul className="dropdown-menu customDropdwn">
                            <li>
                                <Link className="dropdown-item" to="#" onClick={handleAccountNavigate}>My Account</Link>
                            </li>
                            <li>
                                <Link className="dropdown-item" to="#" onClick={handleProfileNavigate}>My Profile</Link>
                            </li>
                            <li>
                                <Link className="dropdown-item" to="#" onClick={handleLogout}>Log Out</Link>
                            </li>
                        </ul>
                    </li>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                </ul>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        {groupedItems?.filter(item => item?.alignment === "left" && item?.display_sections?.includes("header"))?.slice(0, 6).map((item) => {
                            if (item?.alignment === "left" && item?.display_sections?.includes("header")) {
                                const isParentActive = item.children.some(child => location.pathname + location.search === `/${child.permanent_slug}`);
                                return item.children.length > 0 ? (
                                    <li onClick={()=> {setShowMore(false); setActiveMore(false)}} className={`nav-item dropdown ${openDropdowns[item.id] ? "show" : ""}`} key={item.id}>
                                        <NavLink
                                            className={`nav-link dropdown-toggle ${isParentActive ? "active" : ""}`}
                                            to={`/${item.permanent_slug}`}
                                            role="button"
                                            data-bs-toggle="dropdown"
                                            ref={dropdownRef}
                                            onClick={() => handleItemClick(item.id)}
                                        >
                                            {item.title}
                                        </NavLink>
                                        <ul className={`dropdown-menu blogDropdown ${openDropdowns[item.id] ? "show" : ""}`}>
                                            {item.children.map((child) => {
                                                const isActive = location.pathname === `/${child.permanent_slug}`;
                                                return (
                                                    <li key={child.id}>
                                                        <Link className={`dropdown-item ${isActive ? "active" : ""}`} to={`/${child.permanent_slug}`} onClick={() => handleItemClick(item.id)}>
                                                            {child.title}
                                                        </Link>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </li>
                                ) : (
                                    <li onClick={()=> {setShowMore(false); setActiveMore(false)}} key={item.id} className="nav-item">
                                        <NavLink className={`nav-link ${item.permanent_slug === "blogs" ? (isBlogActive ? 'active' : '') : ''}`} to={`/${item.permanent_slug}`}>
                                            {item.title}
                                        </NavLink>
                                    </li>
                                );
                            }
                            return null;
                        })}
                        {console.warn(groupedItems.filter(item => item?.alignment === "left" && item?.display_sections?.includes("header")))}
                           {groupedItems.filter(item => item?.alignment === "left" && item?.display_sections?.includes("header")).slice(6)?.length > 0 && (
                        <li className="nav-item dropdown" style={{ position: "relative" }}>
                            <button 
                                type="button" 
                                className={`nav-link dropdown-toggle ${activeMore ? 'active' : ''}`}
                                onClick={handleMoreClick}
                            >
                                Other
                            </button>
                            {showMore && (
                                <Menu
                                    style={{ width: 256, position: "absolute", top: "56px", boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.0784313725)", zIndex: "9" }}
                                    mode="vertical"
                                    className="show more-menu blogDropdown"
                                    items={groupedItems.filter(item => item?.alignment === "left" && item?.display_sections?.includes("header")).slice(6).map((item) => ({
                                        key: item.id,
                                        label: (
                                            <span style={{fontSize:"1rem",  paddingLeft:"15px", borderRadius:"4px", fontWeight:"500", color: (item.permanent_slug === "resources" && moreActiveMenu?.includes("/e-book")) ? "#fff" : (moreActiveMenu?.includes(item.permanent_slug) ? "#fff" : "#2B2B2B"), padding:"0.875 1rem", cursor: 'pointer', backgroundColor: (item.permanent_slug === "resources" && moreActiveMenu?.includes("/e-book")) ? "#007aff" : (moreActiveMenu?.includes(item.permanent_slug) ? "#007aff" : "transparent") }} className="w-100" onClick={() => { 
                                                navigate(item?.title === "Resources" ? "/e-book" : `/${item.permanent_slug}`); 
                                                setShowMore(false); 
                                                setActiveMore(true);
                                            }}>
                                                {item.title}
                                            </span>
                                        ),
                                        ...(item.children.length > 0 && {
                                            children: item.children.map((childItem) => ({
                                                key: `${item.id}-${childItem.id}`,
                                                label: (
                                                    <span style={{fontSize:"1rem", fontWeight:"500", borderRadius:"4px", paddingLeft:"15px", color: moreActiveMenu?.includes(childItem.permanent_slug) ?"#fff":"#2B2B2B", padding:"0.875 1rem", cursor: 'pointer', backgroundColor: moreActiveMenu?.includes(childItem.permanent_slug) ? "#007aff" : "transparent"}} onClick={() => { 
                                                        navigate(`/${childItem.permanent_slug}`); 
                                                        setShowMore(false); 
                                                        setActiveMore(true);
                                                    }}>
                                                        {childItem.title}
                                                    </span>
                                                )
                                            }))
                                        })
                                    }))}
                                />
                            )}
                        </li>
                    )}

                    </ul>
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        {groupedItems.map((item) => {
                            if (item?.alignment === "right" && item?.display_sections?.includes("header") && item?.slug!=="profile-icon") {
                                return (
                                    <li onClick={()=> { setShowMore(false); 
                                        setActiveMore(true);}} className="nav-item right-menu-text" key={item.id}>
                                        <button type="button" className="btn headerBtn" onClick={() => navigate(`/${item.permanent_slug}`)}>
                                            {item.title}
                                        </button>
                                    </li>
                                );
                            }
                            return null;
                        })}
                        <li className="nav-item dropdown mobileHide">
                            <NavLink className="headerBtn dropdown-toggle dropdownBtn" role="button" data-bs-toggle="dropdown">
                                {userDetail?.data?.profile ? (
                                    <Image preview={false} src={`${fileUrl}${userDetail.data.profile}`} className="img-fluid nav-profile-image" alt="Avatar" />
                                ) : (
                                    <FaRegUserCircle />
                                )}
                            </NavLink>
                            <ul className="dropdown-menu customDropdwn">
                                {groupedItems.filter(item => item?.alignment === "right" && item?.slug==="profile-icon")[0]?.children?.map((item) => {
                                        return (
                                            <li key={item.id}>
                                                <Link className="dropdown-item" to={`/${item.permanent_slug}`} onClick={() => {setShowMore(false);setActiveMore(false);navigate(`/${item.permanent_slug}`);}}>{item.title}</Link>
                                            </li>
                                        );
                                    return null;
                                })}
                                <li>
                                    <Link className="dropdown-item" to="#" onClick={handleLogout}>Log Out</Link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
