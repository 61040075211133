import React, { useState, useEffect } from "react";
import { Collapse, Table, Spin, Input } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { BiTransferAlt } from "react-icons/bi";
import { MdOutlineFileDownload } from "react-icons/md";
import Moment from "react-moment";
import { useGetPurchasedEBooksListingQuery } from "../../store/getSlice";
import DownloadPDFHelper from "../../Helpers/downloadPDFHelper";
import { LoadingOutlined } from '@ant-design/icons';
import PaginationOptionsHelper from "../../Helpers/paginationOptionsHelper";

const PurchasedBooks = () => {
    const [tableSortBy, setTableSortBy] = useState(null);
    const [columnName, setColumnName] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [searchText, setSearchText] = useState(null);
    const [loader, setLoader] = useState(false);
    const { data: getEBooksListing, refetch: reFetchBooks, isLoading: booksLoading } = useGetPurchasedEBooksListingQuery(`?page=${currentPage}&per_page=${pageSize}${searchText ? `&search=${searchText}` : ""}${(tableSortBy && columnName ? `&sort_direction=${tableSortBy}&sort_by=${columnName}` : "")}`);

    useEffect(() => {
        reFetchBooks();
    }, [currentPage, pageSize]);

    useEffect(() => {
        if (getEBooksListing) {
            setLoader(false)
        }

        if(getEBooksListing?.ebooks?.length < 5){
            setPageSize(getEBooksListing?.ebooks?.length)
        }
    }, [getEBooksListing])

    const columns = [
        {
            title: 'S.No.',
            dataIndex: 'serial',
            key: 'serial',
            render: (text, record, index) => {
                const startSerial = (currentPage - 1) * pageSize + 1;
                return startSerial + index;
            },
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            render: (text, record) => (
                record?.title || "N/A"
            ),
            sorter: true,
        },
        {
            title: 'Author',
            dataIndex: 'author',
            key: 'author',
            render: (text, record) => (
                record?.author || "N/A"
            ),
            sorter: true,
        },
        {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            render: (text, record) => (
                record?.price
                    ? new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    }).format(record.price)
                    : "N/A"
            ),
            sorter: true,
        },
        {
            title: 'Purchase Date',
            dataIndex: 'purchase_date',
            key: 'purchase_date',
            render: (text, record) => (
                <Moment format='MM/DD/YYYY'>{record?.purchase_date}</Moment>
            ),
            sorter: true,
        },
        {
            title: 'Download Books',
            render: (text, record) => (
                <button className="downloadIconButton" onClick={() => DownloadPDFHelper(record?.action?.url)}>
                    <MdOutlineFileDownload />
                </button>
            ),
        },
    ];

    const handleSearchChange = (e) => {
        setLoader(true)
        setPageSize(5)
        setCurrentPage(1)
        setSearchText(e?.target?.value || null);
    };

    const pageSizeOptions = PaginationOptionsHelper(getEBooksListing?.total);

    return (
        <div className="transactionWrapper">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="sectionHeader">
                            <h3 className="sectionHeading">Purchased Books</h3>
                            <Input.Search
                                placeholder="Search..."
                                allowClear
                                value={searchText}
                                onChange={handleSearchChange}
                                className="tableSearchInput"
                                style={{ width: "20%" }}
                            />
                        </div>
                        <div className="sectionContent">
                            <Collapse
                                accordion
                                ghost
                                bordered={false}
                                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? -90 : 90} />}
                                expandIconPosition='end'
                                className="transactionCollapse"
                                defaultActiveKey={[1]}
                                items={[
                                    {
                                        key: '1',
                                        label: <p className="panelHeader"><BiTransferAlt /> View Purchased Books</p>,
                                        children: (
                                            <div className="tableWrapper table-responsive">
                                                <Table
                                                    className="transactionTable"
                                                    dataSource={loader || booksLoading ? [] : (getEBooksListing?.ebooks || [])}
                                                    columns={columns}
                                                    onChange={(pagination, filters, sorter) => {
                                                        if (sorter.order) {
                                                            const columnTitle = columns.find(col => col.dataIndex === sorter.field)?.dataIndex;
                                                            setColumnName(columnTitle)

                                                            if (sorter.order === "ascend") {
                                                                setTableSortBy("asc")
                                                            } else {
                                                                setTableSortBy("desc")
                                                            }
                                                        }
                                                    }}
                                                    pagination={{
                                                        total: getEBooksListing?.total || 0,
                                                        current: currentPage,
                                                        pageSize: pageSize,
                                                        onChange: (page, size) => {
                                                            setLoader(true);
                                                            setCurrentPage(page);
                                                            setPageSize(size);
                                                        },
                                                        showSizeChanger: true,
                                                        pageSizeOptions: pageSizeOptions,
                                                    }}
                                                    loading={{
                                                        spinning: loader || booksLoading,
                                                        indicator: <Spin indicator={<LoadingOutlined spin />} />,
                                                    }}
                                                />
                                            </div>
                                        ),
                                    },
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PurchasedBooks;
