import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Table, Button, message, Select, Spin } from "antd";
import { useGetUserDetailQuery } from "../../store/getSlice";
import { useAddAddressDetailsMutation, useDeleteAddressDetailsMutation, useUpDateAddressDetailsMutation } from "../../store/apiSlice";
import { Country, State, City } from 'country-state-city';
import swal from 'sweetalert';
import { LoadingOutlined } from '@ant-design/icons';
import { MdOutlineRefresh } from "react-icons/md";
import PaginationOptionsHelper from "../../Helpers/paginationOptionsHelper";
const { Option } = Select;
const BillingInformation = () => {
    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [addressId, setAddressId] = useState()
    const [formType, setFormType] = useState()
    const countries = Country.getAllCountries();
    const [addAddressDetails, { isLoading: isAddAddressLoading }] = useAddAddressDetailsMutation()
    const [upDateAddressDetails, { isLoading: isUpdateAddressLoading }] = useUpDateAddressDetailsMutation()
    const [deleteAddressDetails, { isLoading: isDeleteAddressLoading }] = useDeleteAddressDetailsMutation()
    const [messageApi, contextHolder] = message.useMessage();
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [isSaving, setIsSaving] = useState(false)
    const [isEditSaving, setIsEditSaving] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const state = State?.getStatesOfCountry(selectedCountry);
    const Cities = City.getCitiesOfState(
        selectedCountry,
        selectedState
    );

    const { data: userDetail, refetch, isLoading:isUserDetailsLoading } = useGetUserDetailQuery("")

    const success = (message) => {
        messageApi.open({
            type: 'success',
            content: message,
        });
    };

    const errorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    useEffect(() => {
        // Fetch countries and set options
        const countries = Country.getAllCountries();
        const countriesOptions = countries.map((item, index) => ({
            key: index,
            label: item.name,
            value: item.isoCode,
        }));

      
    }, []);

    useEffect(()=>{
        if(userDetail?.data?.billing_addresses?.length < 5){
            setPageSize(userDetail?.data?.billing_addresses?.length)
        }
    },[userDetail])


    const handleCountryChange = (isoCode) => {
        setSelectedCountry(isoCode.key);
    };

    // Handle State selection and send ISO code
    const handleStateChange = (isoCode) => {
        setSelectedState(isoCode.key);

    };

    // Handle City selection
    const handleCityChange = (cityName) => {
        setSelectedCity(cityName);
    };


    const showModal = (addressData) => {
        setIsModalOpen(true);
        if (addressData) {
            form.setFieldsValue(addressData);
        } else {
            form.resetFields();
        }
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const onFinish = async (values) => {

        let data = {
            address: values.address,
            billing_zip: values?.billing_zip,
            city: values?.city,
            country: values?.country?.label,
            state: values?.state?.label
        }
        if (formType === 'Add') {
            setIsSaving(true)
            try {
                const res = await addAddressDetails(data)
                if (res.data) {
                    success('Address added succesfully')
                    refetch()
                    form.resetFields()
                    setIsModalOpen(false);
                } else {
                    errorMessage(res.error.data.message)
                }
            } catch (error) {
                errorMessage('An unexpected error occurred')
            } finally {
                setIsSaving(false)
            }
        } else {
            setIsEditSaving(true)
            try {
                const res = await upDateAddressDetails({ data: data, addressId })
                if (res.data) {
                    success('Address updated succesfully')
                    refetch()
                    form.resetFields()
                    setIsModalOpen(false);
                } else {
                    errorMessage(res.error.data.message)
                }
            } catch (error) {
                errorMessage('An unexpected error occurred')
            } finally {
                setIsEditSaving(false)
            }
        }


    };
    const handleConfirm = async (id) => {
        try {
            const res = await deleteAddressDetails(id)
            if (res.data) {
                success(res.data.message)
                refetch()
            } else {
                errorMessage(res.error.data.message)
            }
        } catch (error) {
            errorMessage('An unexpected error occurred')
        }

    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const pageSizeOptions = PaginationOptionsHelper(userDetail?.data?.billing_addresses?.length);

    const columns = [
        {
            title: 'S.No.',
            dataIndex: 'serial',
            key: 'serial',
            render: (text, record, index) => {
                const startSerial = (currentPage - 1) * pageSize + 1;
                return startSerial + index;
            },
        },
        {
            title: 'Address',
            render: (data) => (
                `${data?.address},${data?.country},${data?.city},${data?.state},${data?.billing_zip}`
            ),
        },

        {
            title: 'Options',

            render: (data) => (

                <div className="buttonWrap">
                    <button type="button" className="btn optionBtn me-2" onClick={() => { showModal(data); setAddressId(data?.id); setFormType('Edit') }}>Edit</button>
                    <button type="button" className="btn optionBtn" onClick={() => {
                        setSelectedAddress(data)
                        swal({
                            title: "Warning",
                            text: "Are you sure want to delete the address?",
                            icon: "warning",
                            dangerMode: true,
                            buttons: {
                                cancel: {
                                    text: "Cancel",
                                    value: false,
                                    visible: true,
                                    className: "swal-cancel-confirm-button",
                                    closeModal: true,
                                },
                                confirm: {
                                    text: "OK",
                                    value: true,
                                    visible: true,
                                    className: "swal-custom-confirm-button",
                                    closeModal: true,
                                },
                            },
                        }).then((result) => {
                            console.info({result})
                            if (result) {
                                handleConfirm(data?.id);
                            }
                        });
                    }}>{isDeleteAddressLoading && selectedAddress?.id === data?.id ? <Spin style={{color:"#fff"}} indicator={<LoadingOutlined spin />} size="small" /> : "Delete"}</button>
                </div>
            ),
        },
    ];


    return (
        <div className="billingWrapper">
            <div className="card accountCard h-100">
                {contextHolder}
                <div className="card-header">
                    <h4 className="card-title">Billing Information</h4>
                    <button type="button" className="btn editButton" style={{fontSize:"0.875rem", fontWeight:"400",color:"#000", backgroundColor:"#F8BD00", border:"1px solid #F8BD00", borderRadius:"8px", padding:"0.375rem 0.5rem", transition:"all 0.3s ease-in-out"}} onClick={() => { showModal(); setFormType('Add') }}>Add Address</button>
                </div>
                <div className="tableWrapper table-responsive">
                    <Table
                        className="billingWrap"
                        dataSource={userDetail?.data?.billing_addresses?.map(item => ({ ...item, key: item.id }))}
                        columns={columns}
                        total={userDetail?.data?.billing_addresses?.length}
                        pagination={{
                            pageSize,
                            current: currentPage,
                            onChange: page => setCurrentPage(page),
                            locale: {
                                items_per_page: `/${userDetail?.data?.billing_addresses?.length || 0} Records`,
                            },
                            showSizeChanger: true,
                            pageSizeOptions: userDetail?.data?.billing_addresses?.length < 5 ? [userDetail?.data?.billing_addresses?.length] :pageSizeOptions,
                        }}
                        loading={{
                            spinning: isUserDetailsLoading,
                            indicator: <Spin indicator={<LoadingOutlined spin />} />,
                        }}
                    />
                </div>
            </div>
            <Modal
                title={`${formType} Address Details`}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleOk}
                className="formCard"
                centered
                footer={null}
                okText="Update"
            >
                <div className="modalBody">
                    <Form
                        form={form}
                        name="billingForm"
                        layout="vertical"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <div className="row">
                            <div className="col-md-12">
                                <Form.Item
                                    name="address"
                                    label="Address"

                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input your current  address!",
                                        },
                                        {
                                            pattern: /^[a-zA-Z0-9\s,'-./#]+$/,
                                            message: "Please enter a valid address",
                                        },

                                    ]}
                                >
                                    <Input className="form-control" placeholder="Address" maxLength={255} />
                                </Form.Item>
                            </div>
                            <div className="col-md-12">
                                <Form.Item
                                    name="country"
                                    label="Country"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input your current  country",
                                        },

                                    ]}
                                >

                                    <Select
                                        showSearch
                                        placeholder="Select Country"
                                        labelInValue
                                        onChange={handleCountryChange}
                                        value={selectedCountry}
                                        className="country-select"
                                    >
                                        {countries.map((country) => (
                                            <Option key={country.isoCode} value={country.name} className="d-flex">
                                                {country.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="col-md-12">
                                <Form.Item
                                    name="state"
                                    label="State"
                                    rules={[{ required: true, message: "Please input your current state" }]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select State"
                                        labelInValue
                                        onChange={handleStateChange}
                                        value={selectedState}

                                    >
                                        {state.map((state) => (
                                            <Option key={state.isoCode} value={state.name}>
                                                {state.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </div>

                            <div className="col-md-12">
                                <Form.Item
                                    name="city"
                                    label="City"
                                    rules={[{ required: true, message: "Please input your current city" }]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select City"
                                        onChange={handleCityChange}
                                        value={selectedCity}

                                    >
                                        {Cities.map((city) => (
                                            <Option key={city.isoCode} value={city.name}>
                                                {city.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="col-md-12">
                                <Form.Item
                                    name="billing_zip"
                                    label="ZIP"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input your current  zip",
                                        },
                                        {
                                            pattern: /^[0-9]{5,10}$/,
                                            message: "Please input valid zipcode",
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                const initialZip = getFieldValue('billing_zip');

                                                if (initialZip === value) {
                                                    return Promise.resolve();
                                                }
                                                if (value && value.length === 6) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(
                                                    new Error("Zip code must be exactly 6 digits.")
                                                );
                                            },
                                        }),

                                    ]}
                                >
                                    <Input
                                        className="form-control"
                                        placeholder="Zip"
                                        maxLength={6}
                                        onKeyPress={(e) => {
                                            if (!/[0-9]/.test(e.key)) {
                                                e.preventDefault();
                                            }
                                        }}
                                        onPaste={(e) => {
                                            const paste = (e.clipboardData || window.clipboardData).getData("text");
                                            if (!/^[0-9]+$/.test(paste)) {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                </Form.Item>
                            </div>
                            <div className="buttonWrapper">
                                <Button icon={<MdOutlineRefresh />} loading={isAddAddressLoading || isUpdateAddressLoading} htmlType="submit" l className="submitBtn me-4" disabled={isAddAddressLoading || isUpdateAddressLoading}>Save</Button>
                                <button type="button" className="btn cancelBtn" onClick={handleOk}>Cancel</button>
                            </div>
                        </div>
                    </Form>
                </div>
            </Modal>

        </div>
    );
};



export default BillingInformation;