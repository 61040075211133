import Swal from "sweetalert2";
import axios from "axios";

export default async function DownloadPDFHelper(url) {
    try {
        const token = localStorage.getItem("Token");
        if (token) {
            const response = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                responseType: 'blob',
            });

            const blob = new Blob([response.data], { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'downloaded_ebook.pdf';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            Swal.fire("Success!", "You have successfully downloaded the e-book.", "success");
        }
    } catch (error) {
        Swal.fire("Failed!", "Some error occurred while downloading the e-book.", "error");
    }
}
