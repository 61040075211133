import React, { useState } from "react";
import { ForgotImage, Logo } from "../../constant/images";
import { Button, Form, Input, Alert } from 'antd';
import { Link, useNavigate } from "react-router-dom";
import { IoIosArrowRoundBack } from "react-icons/io";
import { useForgotPasswordMutation } from "../../store/loginRegisterSlice";
import { saveEmail } from "../../store/authSlice";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../../components/footer/unAuthFooter";
import { fileUrl } from "../../Helper";

const ForgotPassword = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const state = useSelector((state) => state?.persistedReducer?.webData)
    const [userForgotPassword, { isLoading }] = useForgotPasswordMutation();
    const [alert, setAlert] = useState(null);

    const onFinish = async (values) => {

        try {
            const res = await userForgotPassword(values)
            if (res.data) {
                dispatch(saveEmail({ email: values }));
                setAlert(<Alert type="success" banner showIcon description={"Reset password code has been sent to your email address"} />);
                setTimeout(() => {
                    navigate('/reset-password');
                }, 1000)

            } else {
                setAlert(<Alert description={`The email address you entered was not found. Please try again or register/sign up with a new email address`} type="error" banner />);
            }
        } catch (error) {
            setAlert(<Alert message={`An unexpected error occurred`} type="error" banner showIcon />);
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>
            <div className="authWrapper">
                <div className="innerWrapper">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-12 col-md-12">
                                <div className="card authCard forgotCard">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-lg-5 col-md-12 d-flex align-items-end justify-content-center">
                                                <div className="formWrapper">
                                                    <div className="logoSection">
                                                        <img src={state?.logo ? `${fileUrl}storage/${state?.logo}` : Logo} className="img-fluid" alt="logo" />
                                                    </div>
                                                    <div className="formSection">
                                                        {alert}
                                                        <h4 className="authTitle">Forgot Your Password</h4>
                                                        <p className="subTitle">Enter your email address, let us help you reset your password.</p>
                                                        <div className="fomrWrap">
                                                            <Form
                                                                name="loginForm"
                                                                layout="vertical"
                                                                onFinish={onFinish}
                                                                onFinishFailed={onFinishFailed}
                                                                autoComplete="off"
                                                            >
                                                                <Form.Item
                                                                    name="email"
                                                                    rules={[
                                                                        {
                                                                            type: "email",
                                                                            message: "The input is not valid E-mail!",
                                                                        },
                                                                        {
                                                                            required: true,
                                                                            message: "Please input your email!",
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input
                                                                        className="form-control"
                                                                        placeholder="Enter your email"
                                                                    />
                                                                </Form.Item>

                                                                <Form.Item>
                                                                    <Button type="primary" htmlType="submit" className="primaryButton">
                                                                        {isLoading ? 'Loading...' : 'Reset Password'}
                                                                    </Button>
                                                                </Form.Item>
                                                                <div className="backLoginRow">
                                                                    <Link to={"/"} className="backLink">
                                                                        <IoIosArrowRoundBack /> Back to Log In
                                                                    </Link>
                                                                </div>
                                                            </Form>
                                                        </div>
                                                        <div className="bottomText">
                                                            <p>
                                                                Need more help? <Link to={"/contact-us"} className="linkText">Contact Us</Link>.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-7 col-md-12 tabHide">
                                                <div className="rightSection">
                                                    <img src={ForgotImage} className="img-fluid" alt="login" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default ForgotPassword;