import React, { useState, useEffect } from "react";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";

export default function PropertyDetailsMap({ propertyData }) {
    const [location, setLocation] = useState(null);

    useEffect(() => {
        if (propertyData && propertyData.latitude && propertyData.longitude) {
            const lat = parseFloat(propertyData.latitude);
            const lng = parseFloat(propertyData.longitude);
            if (!isNaN(lat) && !isNaN(lng)) {
                setLocation({ lat, lng });
            }
        }
    }, [propertyData]);

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    });

    const containerStyle = {
        width: "100%",
        height: "350px",
    };

    if (!isLoaded) return <div>Loading map...</div>;
    if (!propertyData) return <div>No property data available</div>;
    if (!location) return <div>Location not available</div>;

    return (
        <>
            {location ? (
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={location}
                    zoom={10}
                >
                    <Marker position={location} />
                </GoogleMap>
            ) : (<></>)}
        </>
    );
}
