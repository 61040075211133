import React, { useEffect, useState, useMemo } from "react";
import { Avatar, Pagination, Spin, Button, Modal, Form, Input, Table, Radio, Empty, Skeleton } from "antd";
import { useGetUserDetailQuery, useGetEBooksListingQuery } from "../../store/getSlice";
import { usePurchaseEBookMutation } from "../../store/apiSlice";
import { FaDollarSign } from "react-icons/fa";
import EncryptionHelper from "../../Helpers/encryptionHelper";
import Swal from "sweetalert2";
import { IoReload } from "react-icons/io5";
import DownloadPDFHelper from "../../Helpers/downloadPDFHelper";
import DecryptionHelper from "../../Helpers/decryptHelper";
import { LoadingOutlined } from '@ant-design/icons';
import { useLocation } from "react-router-dom";
import { fileUrl } from "../../Helper";
import { BlogImageOne } from "../../constant/images";

const EBook = () => {
    const [purchaseEBook, { isLoading: puchaseLoading }] = usePurchaseEBookMutation();
    const location = useLocation();
    const queryString = location.search;
    const urlParams = new URLSearchParams(queryString);
    const [category, setCategory] = useState(urlParams.get("category"));
    const [category_id, setCategory_id] = useState(urlParams.get(urlParams.get("id")));
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(9);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);
    const [selectedBook, setSelectedBook] = useState(null);
    const [loader, setLoader] = useState(false);
    const [booksListing, setBooksListing] = useState([]);
    const [imageLoading, setImageLoading] = useState([]);
    const [purchased, setPurchased] = useState(false);
    const { data: userDetail } = useGetUserDetailQuery("");
    const [form] = Form.useForm();
    const query = useMemo(() => {
        return `?page=${currentPage}&per_page=${pageSize}${category_id ? `&category_id=${category_id}` : ''}`;
    }, [currentPage, pageSize, category_id]);

    const { data: getEBooksListing, refetch, isLoading: ebooksLoading } = useGetEBooksListingQuery(query);

    useEffect(() => {
        setLoader(true)
        const queryParams = new URLSearchParams(location.search);
        const id = queryParams.get("id");
        const caterory_name = queryParams.get("category");
        setCategory_id(id)
        setCategory(caterory_name);
        refetch();
    }, [location.search]);

    useEffect(() => {
        setLoader(true);
        refetch().finally(() => setLoader(false));
    }, [currentPage, pageSize]);

    useEffect(() => {
        if (getEBooksListing && Array.isArray(getEBooksListing.ebooks)) {
            setBooksListing(getEBooksListing);
            setLoader(false)
            setImageLoading(new Array(getEBooksListing.ebooks.length).fill(purchased ? false : true));
        } else {
            setBooksListing({ pagination: { total: 0 }, ebooks: [] });
            setLoader(false)
        }
        setPurchased(false)
    }, [getEBooksListing]);

    const onFinish = async (values) => {
        const query = `?ebook_id=${selectedBook?.id}&card_id=${selectedCard?.id}&cvv=${encodeURIComponent(EncryptionHelper(values?.cvv))}`;

        try {
            const res = await purchaseEBook(query)
            console.info({response:res})
            if (res?.data) {
                Swal.fire({
                    title: "Success!",
                    text: res?.data?.message,
                    icon: "success",
                    confirmButtonColor: "#007aff",
                });
                setPurchased(true)
                refetch();

            } else {
                Swal.fire({
                    title: "Failed",
                    text: res?.error?.data?.message,
                    icon: "error",
                    confirmButtonColor: "crimson",
                });
            }
        } catch (error) {
           Swal.fire({
                    title: "Failed",
                    text:  "Some error occured",
                    icon: "error",
                    confirmButtonColor: "crimson",
                });
        } finally {
            setSelectedBook(null);
            setSelectedCard(null);
            setIsModalOpen(false);
            form.resetFields();
        }
    };

    const onFinishFailed = ({ errorFields }) => {
        if (errorFields.length > 0) {
            const firstErrorField = errorFields[0].name[0];
            form.scrollToField(firstErrorField, { behavior: "smooth", block: "center" });
        }
    };

    const handlePurchase = (data) => {
        setIsModalOpen(true)
        setSelectedBook(data)
    }


    const renderDescription = (description) => {
        if (description) {
            const parser = new DOMParser();
            const doc = parser.parseFromString(description, "text/html");
            const textContent = doc.body.textContent || "";
            const lines = textContent.split("\n").filter(line => line.trim() !== "");
            return (lines.slice(0, 3).join(" "));
        } else {
            return null;
        }
    }

    const handleOk = () => {
        setSelectedBook(null)
        setSelectedCard(null)
        setIsModalOpen(false)
        form.resetFields()
    }

    const DecryptCardDetisl = (encryptedCardDetails) => {
        try {
            const decryptedDetails = DecryptionHelper(encryptedCardDetails);
            if (decryptedDetails) {
                const parsedDetails = JSON.parse(decryptedDetails);
                return parsedDetails;
            } else {
                console.error("Decryption returned null or undefined");
                return null
            }
        } catch (error) {
            console.error("Error during decryption:", error);
        }
    }

    const columns = [
        {
            render: (data) => (
                <Radio
                    checked={selectedCard?.key === data.key}
                    onChange={() => setSelectedCard(data)}
                />
            ),
        },
        {
            title: "Full Name",
            render: (data) => (
                <>
                    {DecryptCardDetisl(data?.card_details)?.name_on_card}
                    {data?.default && (
                        <span style={{ color: '#1890ff', fontWeight: 'bold' }}> - Primary Card</span>
                    )}
                </>
            ),
        },
        {
            title: "Card Number",
            render: (data) => encryptedCardNumber(DecryptCardDetisl(data?.card_details)?.credit_card_number),
        },
        {
            title: "Expiration Date",
            render: (data) => `${data?.exp_date}`,
        },
    ]

    const encryptedCardNumber = (cardNumber) => {
        if (typeof cardNumber !== 'string' || cardNumber.length < 4) {
            return 'Invalid card number';
        }
        return cardNumber.slice(0, -4).replace(/\d/g, '*') + cardNumber.slice(-4);
    };

    const trimText = (text) => {
        if (typeof text === 'string') {
            if (text.length > 27) {
                return text.substring(0, 27) + '...';
            }
            return text;
        }
        return '';
    };
    
    // In your render method
    {booksListing && booksListing?.ebooks?.map((book, index) => {
        return (
            <div className="col-md-4 mb-4" key={book?.id}>
                {/* Other code */}
                <h3 className="blogTitle">{trimText(book?.title)}</h3>
                {/* Other code */}
            </div>
        )
    })}
    

    const renderCardData = () => (
        <div className="card-list-wrapper">
            <div className="card-list-header">
                <p></p>
            </div>
            <div className="card-list">
                <Table
                    className="billingWrap"
                    dataSource={userDetail?.data?.cards?.map(item => ({ ...item, key: item.id }))}
                    columns={columns}
                    pagination={false}

                />
            </div>
        </div>
    )

    return (
        <div className="listingWrapper">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="titleSection">
                            <h5 className="topTitle">e-Books</h5>
                            <h3 className="mainTitle">{category} Books</h3>

                        </div>
                    </div>
                </div>
                <div className="blogListingWrap">
                    <div className="row">
                        {ebooksLoading || loader ? <div className="spinContainer">
                            <Spin indicator={<LoadingOutlined spin />} size="large" />
                        </div> :
                            (
                                <>
                                    {booksListing?.pagination?.total === 0 ? (
                                        <div style={{ height: "30vh" }}>
                                            <Empty />
                                        </div>
                                    ) : (
                                        booksListing && booksListing?.ebooks?.map((book, index) => {
                                            return (

                                                <div className="col-md-4 mb-4" key={book?.id}>
                                                    <div style={book?.action?.type === "purchase" ? { backgroundColor: "#fff" } : { backgroundColor: "rgb(226 233 245)" }} className="card blogCard" >
                                                        <div className="cardImage">
                                                            {imageLoading[index] && <Skeleton.Image active style={{ width: '100%', height: '100%' }} />}
                                                            <img src={book?.image ? `${fileUrl}storage/${book?.image}` : BlogImageOne} className="img-fluid" alt="book" style={{ display: imageLoading[index] ? 'none' : 'block' }} onLoad={() => {
                                                                setImageLoading(prevLoading => {
                                                                    const newLoading = [...prevLoading];
                                                                    newLoading[index] = false;
                                                                    return newLoading;
                                                                });
                                                            }} />
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="text-container">
                                                                <div className="authorDetailsSection">
                                                                    <div className="name">
                                                                        <Avatar
                                                                            className="bannerAvatar"
                                                                            src="https://img.freepik.com/free-photo/bohemian-man-with-his-arms-crossed_1368-3542.jpg?ga=GA1.1.701385504.1724656585&semt=ais_hybrid"
                                                                        />
                                                                        <h4 className="authorName">{trimText(book?.author)}</h4>
                                                                    </div>
                                                                    <div className="price">
                                                                        <span className="icon"><FaDollarSign style={{ marginBottom: "2.5px" }} /></span>
                                                                        <span className="e-book-price">{book?.price}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="blogContent">
                                                                    <h3 className="blogTitle">{trimText(book?.title)}</h3>
                                                                    <div className="blogDesc">{renderDescription(book?.description)}</div>
                                                                </div>
                                                            </div>
                                                            {book?.action?.type === "purchase" ? (
                                                                <div className="bookPurchase">
                                                                    <Button className="purchaseButton w-100" onClick={() => handlePurchase(book)}>Purchase Now</Button>
                                                                </div>
                                                            ) : (
                                                                <div className="bookPurchase">
                                                                    <Button style={{ backgroundColor: "#909ca7" }} className="purchaseButton w-100" onClick={() => DownloadPDFHelper(book?.action?.url)}>Download</Button>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    )}
                                </>)}
                    </div>
                </div>
            </div>
            <div className="d-flex align-item-center justify-content-center">
                {booksListing?.pagination?.total > 9 && (
                    <Pagination
                        defaultCurrent={1}
                        current={currentPage}
                        total={booksListing?.pagination?.total}
                        pageSize={pageSize}
                        onChange={(page, size) => {
                            setLoader(true);
                            setCurrentPage(page);
                            setPageSize(size);
                            window.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: "smooth",
                            });
                        }}
                    />
                )}
            </div>

            <Modal
                open={isModalOpen}
                onOk={() => handleOk()}
                onCancel={() => handleOk()}
                className="formCard"
                centered
                title="Payment Process"
                okText="Proceed"
                okButtonProps={{ disabled: !selectedCard }}
                width={700}
                footer={[
                    <div key="footer" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        <span style={{ color: 'red', fontSize: '12px' }}>
                            *Refunds are not permitted.
                        </span>
                        <div>
                            <Button key="cancel" onClick={() => handleOk()}>
                                Cancel
                            </Button>
                            <Button loading={puchaseLoading} icon={<IoReload />} className="ms-3" key="proceed" type="primary" onClick={() => { form?.submit() }} disabled={!selectedCard || puchaseLoading}>
                                Proceed
                            </Button>
                        </div>
                    </div>,
                ]}
            >
                <div className="modalBody">
                    {renderCardData()}
                    {selectedCard ? (
                        <Form
                            form={form}
                            name="cardForm"
                            layout="vertical"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                        >
                            <div className="row mt-5">
                                <div className="col-md-6">
                                    <Form.Item
                                        name="cvv"
                                        label="CVV"
                                        rules={[
                                            { required: true, message: 'Please enter the CVV' },
                                            { pattern: /^[0-9]{3,4}$/, message: 'CVV must be 3 or 4 digits' },
                                        ]}
                                    >
                                        <Input.Password
                                            className="form-control w-100"
                                            placeholder="CVV"
                                            maxLength={4}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                        </Form>
                    ) : (<></>)}

                </div>
            </Modal>

        </div>
    );
};

export default EBook;