import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import 'swiper/css';
import 'swiper/css/navigation';
import { GoArrowLeft, GoArrowRight } from "react-icons/go";
import { useGetEBooksListingQuery, useGetUserDetailQuery } from "../../store/getSlice";
import { Avatar, Button, Modal, Form, Table, Input, Radio } from "antd";
import { FaDollarSign } from "react-icons/fa";
import EncryptionHelper from "../../Helpers/encryptionHelper";
import Swal from "sweetalert2";
import { usePurchaseEBookMutation } from "../../store/apiSlice";
import { IoReload } from "react-icons/io5";
import DownloadPDFHelper from "../../Helpers/downloadPDFHelper";
import DecryptionHelper from "../../Helpers/decryptHelper";
import { fileUrl } from "../../Helper";
import { BlogImageOne } from "../../constant/images";

const EbookSection = ({ className }) => {
    const [purchaseEBook, { isLoading: puchaseLoading }] = usePurchaseEBookMutation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);
    const [selectedBook, setSelectedBook] = useState(null);
    const [imageLoading, setImageLoading] = useState([]);
    const [booksListing, setBooksListing] = useState([]);
    const [purchased, setPurchased] = useState(false);
    const [form] = Form.useForm();
    let query = `?per_page=-1`
    const { data: getEBooksListing, refetch } = useGetEBooksListingQuery(query);
    const { data: userDetail, error: userError } = useGetUserDetailQuery("");

    const renderDescription = (description) => {
        if (description) {
            const parser = new DOMParser();
            const doc = parser.parseFromString(description, "text/html");
            const textContent = doc.body.textContent || "";
            const lines = textContent.split("\n").filter(line => line.trim() !== "");
            return (lines.slice(0, 3).join(" "));
        } else {
            return null;
        }
    }

    const handlePurchase = (data) => {
        setIsModalOpen(true)
        setSelectedBook(data)
    }

    const encryptedCardNumber = (cardNumber) => {
        if (typeof cardNumber !== 'string' || cardNumber.length < 4) {
            return 'Invalid card number';
        }
        return cardNumber.slice(0, -4).replace(/\d/g, '*') + cardNumber.slice(-4);
    };

    useEffect(() => {
        if (getEBooksListing && Array.isArray(getEBooksListing.ebooks)) {
            setBooksListing(getEBooksListing);
            setImageLoading(new Array(getEBooksListing.ebooks.length).fill(purchased ? false : true));
        } else {
            setBooksListing({ pagination: { total: 0 }, ebooks: [] });
        }
        setPurchased(false)
    }, [getEBooksListing]);

    const DecryptCardDetisl = (encryptedCardDetails) => {
        try {
            const decryptedDetails = DecryptionHelper(encryptedCardDetails);
            if (decryptedDetails) {
                const parsedDetails = JSON.parse(decryptedDetails);
                return parsedDetails;
            } else {
                console.error("Decryption returned null or undefined");
                return null
            }
        } catch (error) {
            console.error("Error during decryption:", error);
        }
    }

    const columns = [
        {
            render: (data) => (
                <Radio
                    checked={selectedCard?.key === data.key}
                    onChange={() => setSelectedCard(data)}
                />
            ),
        },
        {
            title: "Full Name",
            render: (data) => (
                <>
                    {DecryptCardDetisl(data?.card_details)?.name_on_card}
                    {data?.default && (
                        <span style={{ color: '#1890ff', fontWeight: 'bold' }}> - Primary Card</span>
                    )}
                </>
            ),
        },
        {
            title: "Card Number",
            render: (data) => encryptedCardNumber(DecryptCardDetisl(data?.card_details)?.credit_card_number),
        },
        {
            title: "Expiration Date",
            render: (data) => `${data?.exp_date}`,
        },
    ]

    const handleOk = () => {
        setSelectedBook(null)
        setSelectedCard(null)
        setIsModalOpen(false)
        form.resetFields()
    }

    const onFinish = async (values) => {
        const query = `?ebook_id=${selectedBook?.id}&card_id=${selectedCard?.id}&cvv=${encodeURIComponent(EncryptionHelper(values?.cvv))}`
        try {
            const res = await purchaseEBook(query)
            if (res?.data) {

                Swal.fire({
                    title: "Success!",
                    text: res?.data?.message,
                    icon: "success",
                    confirmButtonColor: "#007aff",
                });
                setPurchased(true)
                refetch();

            } else {
                Swal.fire({
                    title: "Failed",
                    text: res?.error?.data?.message,
                    icon: "error",
                    confirmButtonColor: "crimson",
                });
            }
        } catch (error) {
            Swal.fire({
                title: "Failed",
                text: "Some error occured",
                icon: "error",
                confirmButtonColor: "crimson",
            });
        } finally {
            setSelectedBook(null);
            setSelectedCard(null);
            setIsModalOpen(false);
            form.resetFields();
        }
    };

    const onFinishFailed = (error) => {
        console.error({ error })
    }

    const renderCardData = () => (
        <div className="card-list-wrapper">
            <div className="card-list-header">
                <p></p>
            </div>
            <div className="card-list">
                <Table
                    className="billingWrap"
                    dataSource={userDetail?.data?.cards?.map(item => ({ ...item, key: item.id }))}
                    columns={columns}
                    pagination={false}

                />
            </div>
        </div>
    )


    return (
        <div className={"featuredWrapper " + className}>
            <div className="innerWrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="titleSection">
                                <h5 className="topTitle">eBooks</h5>
                                <h3 className="mainTitle">Read Our eBooks</h3>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <Swiper
                                spaceBetween={30}
                                slidesPerView={3}
                                navigation={{
                                    nextEl: ".custom-next",
                                    prevEl: ".custom-prev",
                                }}
                                modules={[Navigation]}
                                breakpoints={{
                                    1024: {
                                        slidesPerView: 3,
                                        spaceBetween: 20
                                    },
                                    600: {
                                        slidesPerView: 2,
                                        spaceBetween: 15
                                    },
                                    320: {
                                        slidesPerView: 1,
                                        spaceBetween: 10
                                    },
                                }}
                            >
                                {booksListing && booksListing?.ebooks?.map((book, index) => (

                                    <SwiperSlide>
                                        <div className="card book-card" key={book?.id} style={book?.action?.type === "purchase" ? { backgroundColor: "#fff" } : { backgroundColor: "rgb(226 233 245)" }} >
                                            <div className="book-image">
                                                <img src={book?.image ? `${fileUrl}storage/${book?.image}` : BlogImageOne} className="img-fluid" alt="book" style={{ display: imageLoading[index] ? 'none' : 'block' }} onLoad={() => {
                                                    setImageLoading(prevLoading => {
                                                        const newLoading = [...prevLoading];
                                                        newLoading[index] = false;
                                                        return newLoading;
                                                    });
                                                }} />
                                            </div>
                                            <div className="book-card-body" >
                                                <div className="book-details">
                                                    <div className="author-details-section">
                                                        <div className="name">
                                                            <Avatar
                                                                className="bannera-vatar me-2"
                                                                src="https://img.freepik.com/free-photo/bohemian-man-with-his-arms-crossed_1368-3542.jpg?ga=GA1.1.701385504.1724656585&semt=ais_hybrid"
                                                            />
                                                            <h4 className="author-name font-weight-bold">{book?.author}</h4>
                                                        </div>
                                                        <div className="book-price-container">
                                                            <span className="icon"><FaDollarSign /></span>
                                                            <span className="book-price">{book?.price}</span>
                                                        </div>
                                                    </div>
                                                    <div className="book-card-content">
                                                        <h3 className="book-card-title">{book?.title}</h3>
                                                        <div className="book-card-description">{renderDescription(book?.description)}</div>
                                                    </div>
                                                </div>
                                                {book?.action?.type === "purchase" ? (
                                                    <div className="bookPurchase">
                                                        <Button className="purchaseButton w-100" onClick={() => handlePurchase(book)}>Purchase Now</Button>
                                                    </div>
                                                ) : (
                                                    <div className="bookPurchase">
                                                        <Button style={{ backgroundColor: "#909ca7" }} className="purchaseButton w-100" onClick={() => DownloadPDFHelper(book?.action?.url)}>Download</Button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}

                            </Swiper>
                            <div className="custom-prev navBtn">
                                <GoArrowLeft size={30} />
                            </div>
                            <div className="custom-next navBtn">
                                <GoArrowRight size={30} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                open={isModalOpen}
                onOk={() => handleOk()}
                onCancel={() => handleOk()}
                className="formCard"
                centered
                title="Payment Process"
                okText="Proceed"
                okButtonProps={{ disabled: !selectedCard }}
                width={700}
                footer={[
                    <div key="footer" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                        <span style={{ color: 'red', fontSize: '12px' }}>
                            *Refunds are not permitted.
                        </span>
                        <div>
                            <Button key="cancel" onClick={() => handleOk()}>
                                Cancel
                            </Button>
                            <Button loading={puchaseLoading} icon={<IoReload />} className="ms-3" key="proceed" type="primary" onClick={() => { form?.submit() }} disabled={!selectedCard}>
                                Proceed
                            </Button>
                        </div>
                    </div>,
                ]}
            >
                <div className="modalBody">
                    {renderCardData()}
                    {selectedCard ? (
                        <Form
                            form={form}
                            name="cardForm"
                            layout="vertical"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                        >
                            <div className="row mt-5">
                                <div className="col-md-6">
                                    <Form.Item
                                        name="cvv"
                                        label="CVV"
                                        rules={[
                                            { required: true, message: 'Please enter the CVV' },
                                            { pattern: /^[0-9]{3,4}$/, message: 'CVV must be 3 or 4 digits' },
                                        ]}
                                    >
                                        <Input.Password
                                            className="form-control w-100"
                                            placeholder="CVV"
                                            maxLength={4}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                        </Form>
                    ) : (<></>)}

                </div>
            </Modal>
        </div>
    );
};

export default EbookSection;
