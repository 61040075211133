import React, { useEffect, useState } from "react";
import { Row, Col, Image, message, Modal } from "antd";
import { FaCamera, FaLocationDot, FaArrowLeft, FaHeart } from "react-icons/fa6";
import { GalleryImage1, GalleryImage2, GalleryImage3, GalleryImage4, GalleryImage5, GalleryImage6 } from "../../constant/images";
import { useNavigate } from 'react-router-dom';
import { FaRegHeart } from "react-icons/fa";
import {
    useRemoveFavouriteMutation,
    useAddFavouriteMutation
} from "../../store/apiSlice";

const Gallery = ({ getPropertyDetails }) => {
    const [addFavourite] = useAddFavouriteMutation();
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    const [isAddSaving, setIsAddSaving] = useState(false)
    const [isRemoveSaving, setIsRemoveSaving] = useState(false)
    const [removeFavourite] = useRemoveFavouriteMutation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [propertyDetails, setPropertyDetails] = useState();

    const scrollToMap = () => {
        const mapDiv = document.getElementById('map');
        if (mapDiv) {
            mapDiv.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(()=>{
      if(getPropertyDetails?.data){
        setPropertyDetails(getPropertyDetails?.data)
      }
    },[getPropertyDetails])



    const imageArray = [
        GalleryImage1, GalleryImage2, GalleryImage3, GalleryImage4, GalleryImage5, GalleryImage6, "https://img.freepik.com/free-photo/3d-electric-car-building_23-2148972401.jpg?ga=GA1.1.701385504.1724656585&semt=ais_hybrid"
    ]

    const success = (message) => {
        messageApi.open({
            type: "success",
            content: message,
        });
    };

    const errorMessage = (message) => {
        messageApi.open({
            type: "error",
            content: message,
        });
    };

    const goBack = () => {
        navigate(-1);
    };

    const toggleWishlist = async (id) => {
        let value = {
            property_id: id,
        };
        setIsAddSaving(true)
        try {
            const res = await addFavourite(value);
            if (res.data) {
                setPropertyDetails(prev => ({ ...prev, is_favorite: true }));
                success("Added successfully");

            } else {
                errorMessage(res.error.data.message);
            }
        } catch {
            errorMessage("An unexpected error occurred");
        } finally {
            setIsAddSaving(false)
        }
    };
    const deletefavourite = async (id) => {
       
        setIsRemoveSaving(true)
        try {
            const res = await removeFavourite(id);
            if (res.data) {
                setPropertyDetails(prev => ({ ...prev, is_favorite: false }));
                success(res.data.message);
            } else {
                errorMessage(res.error.data.message);
            }
        } catch {
            errorMessage("An unexpected error occurred");
        } finally {
            setIsRemoveSaving(false)
        }
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="galleryWrapper">
            {contextHolder}
            <div className="innerWrapper">
                <Row
                    className="h-100"
                    style={{ cursor: "pointer" }}
                    onClick={() => setIsModalOpen(true)}
                >
                    <Col span={14}>
                        <div className="galleryImage">
                            <Image
                                preview={false}
                                src={imageArray[0]}
                                className="img-fluid"
                                alt="property"
                            />
                        </div>
                    </Col>
                    <Col span={10}>
                        <Row className="h-100">
                            {imageArray?.length > 2 && imageArray.slice(1).map((image, index) => (
                                <Col span={12} key={index}>
                                    <div className="galleryImage">
                                        <Image
                                            preview={false}
                                            src={image}
                                            className="img-fluid"
                                            alt={`property-${index + 1}`}
                                        />
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                </Row>
                <div className="bottomRow">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="buttonWrap">
                                    <button type="button" className="btn photoBtn" onClick={goBack}>
                                        <FaArrowLeft /> Back
                                    </button>
                                </div>
                            </div>
                            <div className="col">
                                <div className="buttonsWrap">
                                        {propertyDetails?.is_favorite ? (
                                            <button  onClick={() => {
                                                if (!isRemoveSaving) {
                                                    deletefavourite(propertyDetails?.listing_key);
                                                }
                                            }} type="button" className="btn iconBtn me-4">
                                            <FaHeart
                                            color="red"
                                            />
                                             </button>
                                        ) : (
                                            <button onClick={() => {
                                                if (!isAddSaving) {
                                                    toggleWishlist(propertyDetails?.listing_key);
                                                }
                                            }} type="button" className="btn iconBtn me-4">
                                            <FaRegHeart
                                                
                                            />
                                            </button>
                                        )}
                                   
                                    <button type="button" className="btn iconBtn" onClick={scrollToMap} >
                                        <FaLocationDot />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                className="gallayry-modal"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleOk}
                footer={false}
                centered
                width={1200}
            >
                <Row className="h-100 px-3 py-3">
                    {imageArray?.map((imageSrc, index) => (
                        ((index) % 3 === 0 || index === 0) ? (
                            <Col md={24} key={index}>
                                <div className="galleryImage w-100">
                                    <Image
                                        preview={false}
                                        src={imageSrc}
                                        className="img-fluid"
                                        alt={`property-${index}`}
                                    />
                                </div>
                            </Col>
                        ) : (
                            <Col className="mt-3 mb-3 px-2 py-2" md={12} key={index}>
                                <div className="galleryImage w-100">
                                    <Image
                                        preview={false}
                                        src={imageSrc}
                                        className="img-fluid"
                                        alt={`property-${index}`}
                                    />
                                </div>
                            </Col>
                        )
                    ))}
                </Row>
            </Modal>
        </div>
    );
}

export default Gallery;