import CryptoJS from "crypto-js";

export default function DecryptionHelper(encryptedData) {
    const secretKey = process.env.REACT_APP_SECRET_KEY;
    if (!secretKey) {
        console.error("Missing secret key!");
        return null;
    }

    const key = CryptoJS.SHA256(secretKey).toString(CryptoJS.enc.Hex);
    const parsedKey = CryptoJS.enc.Hex.parse(key);

    const data = CryptoJS.enc.Base64.parse(encryptedData);
    
    const iv = CryptoJS.lib.WordArray.create(data.words.slice(0, 4), 16);
    const ciphertext = CryptoJS.lib.WordArray.create(data.words.slice(4), data.sigBytes - 16);

    const decrypted = CryptoJS.AES.decrypt({ ciphertext: ciphertext }, parsedKey, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
}
