import React from "react";

const PropertyInformation = ({ details }) => {
    return (
        <div className="container py-4">
            <div className="row">
                <div className="col-md-6">
                    <h4 className="mb-3">Features</h4>
                    <ul className="list-group">
                        <li className="list-group-item">
                            <strong>Asking Price:</strong> {details?.price ? details.price.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '$0.00'}
                        </li>
                        <li className="list-group-item">
                            <strong>Property Size:</strong> {details?.lot_size ? `${details.lot_size} Sq Ft` : 'No data found'}
                        </li>
                        <li className="list-group-item">
                            <strong>Year Built:</strong> {details?.year_built ?? 'No data found'}
                        </li>
                        <li className="list-group-item">
                            <strong>Heating:</strong> {details?.heating ?? 'No data found'}
                        </li>
                    </ul>
                    <div className="col-12 mt-5">
                    <h4>Description</h4>
                    <p className="border p-3 bg-light">{details?.description ?? 'No data found'}</p>
                </div>
                </div>
                <div className="col-md-6">
                    <h4 className="mb-3">Facts</h4>
                    <ul className="list-group">
                        <li className="list-group-item"><strong>Property ID:</strong> {details?.listing_key ?? 'No data found'}</li>
                        <li className="list-group-item"><strong>Property Type:</strong> {details?.property_type ?? 'No data found'}</li>
                        <li className="list-group-item"><strong>Legal Description:</strong> {details?.legal_description ?? 'No data found'}</li>
                        <li className="list-group-item"><strong>Parcel Number:</strong> {details?.parcel_number ?? 'No data found'}</li>
                        <li className="list-group-item"><strong>Bedrooms:</strong> {details?.bedrooms_total ?? 'No data found'}</li>
                        <li className="list-group-item"><strong>Bathrooms:</strong> {details?.bathrooms_total ?? 'No data found'}</li>
                        <li className="list-group-item"><strong>Cooling:</strong> {details?.cooling ?? 'No data found'}</li>
                        <li className="list-group-item"><strong>Construction Type:</strong> {details?.construction_type ?? 'No data found'}</li>
                        <li className="list-group-item"><strong>Roof Type:</strong> {details?.roof ?? 'No data found'}</li>
                        <li className="list-group-item"><strong>MLS Number:</strong> {details?.mls_number ?? 'No data found'}</li>
                        <li className="list-group-item"><strong>County:</strong> {details?.country ?? 'No data found'}</li>
                        <li className="list-group-item"><strong>City:</strong> {details?.postal_city ?? 'No data found'}</li>
                        <li className="list-group-item"><strong>Elementary School District:</strong> {details?.elementary_school_district ?? 'No data found'}</li>
                        <li className="list-group-item"><strong>High School District:</strong> {details?.high_school_district ?? 'No data found'}</li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default PropertyInformation;
