import React, { useState, useEffect } from "react";
import { FaArrowRight } from "react-icons/fa";
import { Skeleton } from "antd";
import { useGetBlogProductsQuery } from "../../store/getSlice";
import { fileUrl } from "../../Helper";
import {
    BlogImageOne,
} from "../../constant/images";

const AdsSection = ({ blogId }) => {
    const [blogData, setBlogData] = useState([])
    const [imageLoading, setImageLoading] = useState([]);
    const { data: blogDetails } = useGetBlogProductsQuery(blogId);

    useEffect(() => {
        if (blogDetails && Array.isArray(blogDetails.data)) {
            setBlogData(blogDetails)
            setImageLoading(new Array(blogDetails.data.length).fill(true));
        }
    }, [blogDetails])
    return (
        <>
            {blogData?.data?.length > 0 ? (
                <div className="ads">
                    <p className="prducts-heading" style={{marginTop:"36px"}}>Sponsored Products</p>
                    <div className="ads-div">
                        {blogData?.data?.map((ad, index) => (
                            <div className="ads-warpper w-100 mt-5">
                                <a target="_blank" rel="noopener noreferrer" href={ad?.link} style={{ cursor: "pointer", textDecoration: "none" }} class="card ads-container">
                                    {imageLoading[index] && <Skeleton.Image active style={{ width: '100%', height: '200px' }} />}
                                    <img class="card-img-top ads-image" src={ad?.image_path ? `${fileUrl}storage/${ad?.image_path}` : BlogImageOne} style={{ display: imageLoading[index] ? 'none' : 'block' }} alt="Product Image" onLoad={() => {
                                        setImageLoading(prevLoading => {
                                            const newLoading = [...prevLoading];
                                            newLoading[index] = false;
                                            return newLoading;
                                        });
                                    }}/>
                                    <div class="card-body ads-body">
                                        <div className="ads-text-wrapper">
                                            <p className="ads-title">{ad?.name}</p>
                                            <p class="card-text ads-description">{ad?.description}</p>
                                        </div>
                                        <a target="_blank" rel="noopener noreferrer" className="ads-link" href={ad?.link}>Go to site {<FaArrowRight className="ms-3" />}</a>
                                    </div>
                                </a>
                            </div>
                        ))}
                    </div>
                </div>
            ) : (<></>)}
        </>
    );
}

export default AdsSection;