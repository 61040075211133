import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Table, Button, message, Spin } from "antd";
import { useGetRecentSearchQuery, useGetUserDetailQuery } from "../../store/getSlice";
import { useAddZipCodeMutation, useDeleteZipCodeMutation, useUpDateZipCodeMutation } from "../../store/apiSlice";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { IoReload } from "react-icons/io5";
import { LoadingOutlined } from '@ant-design/icons';

const ZipCode = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate()
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 5;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [zipCodeId, setZipCodeId] = useState()
    const [formType, setFormType] = useState()
    const [addZipCode,{isLoading: isAddZipCodeLoading}] = useAddZipCodeMutation()
    const [updateZipCode,{isLoading: isUpdateZipCodeLoading}] = useUpDateZipCodeMutation()
    const [deleteZipCode] = useDeleteZipCodeMutation()
    const { data: userDetail, refetch, isLoading: isUserDetailsLoading } = useGetUserDetailQuery("")
    const { data: recentSearch, refetch: refetchRecentZip, isLoading:isSearchZipLoading } = useGetRecentSearchQuery()
    const [messageApi, contextHolder] = message.useMessage();
    const [isSaving, setIsSaving] = useState(false)
    const [isEditSaving, setIsEditSaving] = useState(false)
    const success = (message) => {
        messageApi.open({
            type: 'success',
            content: message,
        });
    };

    const errorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    useEffect(() => {
        refetchRecentZip()
    }, [])



    const showModal = (zipCode) => {
        setIsModalOpen(true);
        if (zipCode) {
            form.setFieldsValue(zipCode);
        } else {
            form.resetFields();
        }
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const onFinish = async (values) => {

        const isDuplicate = userDetail?.data?.zips?.some(zip => zip.zip_code === values.zip_code);

        if (isDuplicate) {
            errorMessage('This zip code already exists. Please add a unique zip code.');
            return;
        }

        if (formType === 'Add') {
            setIsSaving(true);
            try {
                const res = await addZipCode(values);
                if (res.data) {
                    success('Zip code added successfully');
                    refetch();
                    form.resetFields();
                    setIsModalOpen(false);
                } else {
                    errorMessage(res.error.data.message);
                }
            } catch (error) {
                errorMessage('An unexpected error occurred');
            } finally {
                setIsSaving(false);
            }
        } else {
            setIsEditSaving(true);
            try {
                const res = await updateZipCode({ data: values, zipCodeId });
                if (res.data) {
                    success('Zip code updated successfully');
                    refetch();
                    form.resetFields();
                    setIsModalOpen(false);
                } else {
                    errorMessage(res.error.data.message);
                }
            } catch (error) {
                errorMessage('An unexpected error occurred');
            } finally {
                setIsEditSaving(false);
            }
        }
    };

    const handleConfirm = async (id) => {

        try {
            const res = await deleteZipCode(id)
            if (res.data) {
                success(res.data.message)
                refetch()

            } else {
                errorMessage(res.error.data.message)
            }
        } catch (error) {
            errorMessage('An unexpected error occurred')
        }

    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    const columns = [
        {
            title: 'Zip',
            render: (data) => (
                `${data?.zip_code}`
            ),
        },

        {
            title: 'Manage Zip Codes',

            render: (data) => (

                <div className="buttonWrapAction">
                    <button type="button" className="btn optionBtn" onClick={() => { showModal(data); setZipCodeId(data?.id); setFormType('Edit') }}>Edit</button>
                    <button type="button" className="btn optionBtn" onClick={() => {
                        swal({
                            title: "Warning",
                            text: "Are you sure you want to delete the zip code?",
                            icon: "warning",
                            dangerMode: true,
                            buttons: {
                                cancel: {
                                    text: "Cancel",
                                    value: null,
                                    visible: true,
                                    className: "swal-cancel-confirm-button",
                                    closeModal: true,
                                },
                                confirm: {
                                    text: "OK",
                                    value: true,
                                    className: "swal-custom-confirm-button",
                                    closeModal: true,
                                }
                            },
                        })
                            .then(ok => {
                                if (ok) {
                                    handleConfirm(data?.id)
                                }
                            })
                    }}>Delete</button>
                </div>

            ),
        },
    ];
    const zipCodeColumns = [
        {
            title: 'Zip',
            render: (data) => (
                `${data?.search_term}`
            ),
        },

        {
            title: 'View Properties',
            render: (data) => (
                <div className="d-flex justify-content-evenly">
                    <button
                        onClick={() => navigate(`/search`, { state: { searchTerm: data?.search_term } })}
                        className="btn optionBtn"
                    >
                        View Property
                    </button>
                </div>
            ),
        },
    ];


    return (
        <div className="billingWrapper">
            <div className="card accountCard">
                {contextHolder}
                <div className="card-header">
                    <h4 className="card-title">Zip Codes</h4>
                    <button type="button" className="btn editButton" disabled={userDetail?.data?.zips?.length >= 10} style={{fontSize:"0.875rem", fontWeight:"400",color:"#000", backgroundColor:"#F8BD00", border:"1px solid #F8BD00", borderRadius:"8px", padding:"0.375rem 0.5rem", transition:"all 0.3s ease-in-out"}} onClick={() => { showModal(); setFormType('Add') }}>Add Zip Code</button>
                </div>

                <div className="tableWrapper">
                    <Table
                        className="billingWrap"
                        dataSource={userDetail?.data?.zips?.map(item => ({ ...item, key: item.id }))}
                        columns={columns}
                        pagination={{
                            pageSize,
                            current: currentPage,
                            onChange: page => setCurrentPage(page),
                        }}
                        loading={{
                            spinning: isUserDetailsLoading,
                            indicator: <Spin indicator={<LoadingOutlined spin />} />,
                        }}
                    />
                </div>
            </div>
            <div className="card accountCard mt-4">
                {contextHolder}
                <div className="card-header">
                    <h4 className="card-title">Recently Searched Zip Codes</h4>
                </div>
                <div className="tableWrapper">
                    <Table
                        className="billingWrap"
                        dataSource={recentSearch}
                        columns={zipCodeColumns}
                        pagination={{
                            pageSize,
                            current: currentPage,
                            onChange: page => setCurrentPage(page),
                        }}
                        loading={{
                            spinning: isSearchZipLoading,
                            indicator: <Spin indicator={<LoadingOutlined spin />} />,
                        }}
                    />
                </div>
            </div>
            <Modal
                title={`${formType} Zip Code`}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleOk}
                className="formCard add-zipcode-modal"
                centered
                footer={null}
                okText="Update"
            >
                <div className="modalBody">
                    <Form
                        form={form}
                        name="billingForm"
                        layout="vertical"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <div className="row">

                            <div className="col-md-12">
                                <Form.Item
                                    name="zip_code"
                                    label="ZipCode"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input your current  zip",
                                        },
                                        {
                                            pattern: /^[0-9]{5,10}$/,
                                            message: "Please input valid zipcode",
                                        },

                                    ]}
                                >
                                    <Input className="form-control" placeholder="Zip" />
                                </Form.Item>
                            </div>
                            <div className="buttonWrapper">
                                <Button loading={isAddZipCodeLoading || isUpdateZipCodeLoading} icon={<IoReload />} htmlType="submit" className="submitBtn me-4" disabled={isAddZipCodeLoading || isUpdateZipCodeLoading}>Save</Button>
                                <button type="button" className="btn cancelBtn" onClick={handleOk}>Cancel</button>
                            </div>
                        </div>
                    </Form>
                </div>
            </Modal>

        </div>
    );
};

export default ZipCode;