import React, { useEffect, useState } from "react";
import FeaturedSection from "../../components/features/features";
import Gallery from "./gallery";
import { Anchor, Spin } from "antd";
import { FaLocationDot } from "react-icons/fa6";
import Survey from "../SurveyJS/SurveyComponent";
import { Link, useLocation } from "react-router-dom";
import PropertyInformation from "./propertyInformation";
import CalculatePayment from "./calculatePayment";
import { useGetPropertyDetailsMutation } from "../../store/getSlice";
import CreditCardSection from "./creditCardSection";
import { FaRegSquarePlus, FaRegSquareMinus } from "react-icons/fa6";
import PropertyDetailsMap from "../../Helpers/propertyDetailsMap";
import { LoadingOutlined } from '@ant-design/icons';

const PropertyDetails = () => {
    const location = useLocation()
    const [ getPropertyDetails, {isLoading: propertyDataLoading }] = useGetPropertyDetailsMutation();
    const [propertyType, setPropertyType] = useState();
    const [propertyData, setPropertyData] = useState(null);
    const [ isCalculatorShow, setIsCalculatorShow ] = useState(false);

    const showCalculator = () => {
        setIsCalculatorShow((prev) => !prev);
    }

    const getPropertyData = async() =>{
        
        try {
            const response = await getPropertyDetails(location?.state?.id);
            if (response?.data) {
                setPropertyData(response?.data)
            }
        } catch (error) {
           console.error({error})
        }
        
    }

    useEffect(()=>{
      if(location?.state?.id){
        getPropertyData()
      }
    },[])
   

    return (
        <>
        {propertyData ? (
            <>
              <div className="propertyDetailsWrapper pb-5">
            <Gallery getPropertyDetails={propertyData}/>
            <div className="container">
                <div className="detailsSection">
                    <div className="anchors">
                        <Anchor
                            direction="horizontal"
                            items={[
                                {
                                    key: 'details',
                                    href: '#details',
                                    title: 'Property Details',
                                },
                                {
                                    key: 'contact',
                                    href: '#contact',
                                    title: 'Real Estate Contact Information',
                                },
                                {
                                    key: 'price',
                                    href: '#price',
                                    title: 'Asking Price Info',
                                },
                                {
                                    key: 'payment',
                                    href: '#payment',
                                    title: 'Calculate Payment',
                                },
                                {
                                    key: 'realEstate',
                                    href: '#realEstate',
                                    title: 'Contact Real Estate Agent',
                                },
                            ]}
                        />
                    </div>
                    {propertyDataLoading ? <Spin indicator={<LoadingOutlined spin />} style={{ display: "flex", alignItems: "center", justifyContent: "center" }} size="large" /> :
                        <div className="card detailsCard">
                            <div className="card-body p-0">
                                <div className="topInformation">
                                    <div className="inormation">
                                        <h4 className="name">{propertyData?.data?.unparsed_address ?? 'No data found'}</h4>
                                        <p className="location"><FaLocationDot />{propertyData?.data?.postal_city}, {propertyData?.data?.state_or_province},{propertyData?.data?.country}-{propertyData?.data?.postal_code}</p>
                                        <div className="bottomDetails">
                                            <span className="dtl">{propertyData?.data?.property_type ?? 'No data found'}</span>
                                            <span className="divider"></span>
                                            <span className="dtl">{propertyData?.data?.property_subtype ?? 'No data found'}</span>
                                        </div>
                                    </div>
                                    <div className="price">
                                        <h4>
                                            {propertyData?.data?.price
                                                ? propertyData?.data?.price.toLocaleString('en-US', {
                                                    style: 'currency',
                                                    currency: 'USD',
                                                })
                                                : '$0.00'}
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="card-header" id="details">
                                <h4 className="card-title">Property Details</h4>
                            </div>
                            <div className="card-body">
                                <PropertyInformation details={propertyData?.data} />
                            </div>
                            <div id="map">
                            {/* <Map height={350} propertyListing={[propertyData?.data]} zoom={5}  /> */}
                            {propertyData?.data &&  <PropertyDetailsMap propertyData={propertyData?.data}/>}

                            </div>
                            <div className="card-header" id="contact">
                                <h4 className="card-title">Real Estate Contact Information</h4>
                            </div>
                            <div className="card-body">
                                <div className="agentInfo">
                                    <h4 className="agentName">Agent (S)</h4>
                                    <p className="agentDetails">{propertyData?.data?.agent_full_name ?? 'No data found'}</p>
                                    <p className="agentDetails">{propertyData?.data?.agent_email ?? 'No data found'}</p>
                                    <p className="agentDetails">Website: {propertyData?.data?.listing_url ?? 'No data found'}</p>
                                    <p className="phone">
                                        <Link to={"tel:888-773-4547"}>{propertyData?.data?.agent_office_phone ?? 'No data found'}</Link>
                                    </p>
                                    <p className="bottomText">
                                        Can’t Find a contact or is there a problem with this listing? <Link to={"/contact-us"} className="link">Contact Us</Link>
                                    </p>
                                </div>
                            </div>

                            <div className="card-header" id="price">
                                <h4 className="card-title">Asking Price Information</h4>
                            </div>

                            <div className="card-body">
                                <div className="priceWrapper">
                                    <p className="subText">
                                        {propertyData?.data?.price
                                            ? propertyData?.data?.price.toLocaleString('en-US', {
                                                style: 'currency',
                                                currency: 'USD',
                                            })
                                            : '$0.00'}
                                    </p>
                                </div>
                            </div>

                            <div className="card-header calculateHeader" id="payment">
                                <h4 className="card-title">Calculate Payment</h4>
                                <button type="button" className="btn collapsedIconBtn" onClick={ () => showCalculator() }>
                                    {
                                        isCalculatorShow ? <FaRegSquareMinus /> : <FaRegSquarePlus />
                                    }
                                </button>
                            </div>
                            <div className="card-body">
                                {
                                    isCalculatorShow && (
                                        <CalculatePayment amount={propertyData?.data?.price} type={propertyType} />
                                    )
                                }
                                <CreditCardSection />
                            </div>
                            
                            <div className="card-header" id="realEstate">
                                <h4 className="card-title">Contact Real Estate Professional In Your Area</h4>
                            </div>

                            <div className="card-body">
                                <Survey />
                            </div>

                        </div>
                    }
                </div>
            </div>
            <FeaturedSection className="bgGrey mb-0" />
        </div>
            </>
        ) :(
            <div className="d-flex justify-content-center align-items-center property-details-loader-wrapper">
                <Spin indicator={<LoadingOutlined spin />}/>
            </div>
        )}
      
        </>
    );
};

export default PropertyDetails;