import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { WhiteLogo } from "../../constant/images";
import { SlLocationPin } from "react-icons/sl";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { currentAddress } from "../../store/authSlice";
import { fileUrl } from "../../Helper";

const TopHeader = () => {
    const state = useSelector((state) => state?.persistedReducer?.webData);
    const dispatch = useDispatch();
    const address = useSelector((state) => state?.persistedReducer?.address);
  
    useEffect(() => {
     
        if (!address) {
                 axios
                .get(`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}`)
                .then(() => {
                    if (navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition(function (position) {
                            let lat = position.coords.latitude;
                            let lng = position.coords.longitude;

                            let latlng = { lat: lat, lng: lng };
                            let geocoder = new window.google.maps.Geocoder();

                            geocoder?.geocode({ location: latlng }, function (results, status) {
                                if (status === 'OK') {
                                    if (results[0]) {
                                        let addressComponents = results[0].address_components;
                                        let city = '';
                                        let state = '';
                                        let country = '';
                                        let area = '';

                                        addressComponents.forEach(function (component) {
                                            if (component.types.includes('locality')) {
                                                city = component.long_name;
                                            }
                                            if (component.types.includes('administrative_area_level_1')) {
                                                state = component.long_name;
                                            }
                                            if (component.types.includes('country')) {
                                                country = component.long_name;
                                            }
                                            if (component.types.includes('sublocality_level_1')) {
                                                area = component.long_name;
                                            }
                                        });
                                        let addressParts = [area, city, state, country];
                                        let current_address = addressParts.filter(Boolean).join(", ");

                                        dispatch(currentAddress(current_address));
                                    } else {
                                        console.log("No results found");
                                    }
                                } else {
                                    console.log("Geocoder failed due to: " + status);
                                }
                            });
                        });
                    } else {
                        console.log("Geolocation is not supported by this browser.");
                    }
                })
                .catch((error) => console.error("Error in geocoding:", error));
        }
    }, [address, dispatch]);

    return (
        <div className="topHeader">
            <div className="container">
                <div className="row">
                    <div className="col-md-4 ordr-2 md-flex">
                        <div className="locationWrap">
                            <p className="locationText">
                                {address ? (
                                    <>
                                        <SlLocationPin /> {address}
                                    </>
                                ) : (
                                    ''
                                )}
                            </p>
                        </div>
                    </div>
                    <div className="col-md-4 ordr-1">
                        <div className="logoSection text-center">
                            <NavLink className="navbar-brand" to={"/homepage"}>
                                <img src={state?.logo ? `${fileUrl}storage/${state?.logo}` : WhiteLogo} className="img-fluid" alt="logo" />
                            </NavLink>
                        </div>
                    </div>
                    {/* <div className="col-md-6">
                        <div className="topHeaderMenu">
                            <div className="topMenu">
                                <Link to={"tel:+91 987-654-3210"} className="topMenuLink">
                                    <LuPhone /> {state?.contact_number}
                                </Link>
                                <Link to={"mailto:support@rainfrog.com"} className="topMenuLink">
                                    <TbMail /> {state?.email}
                                </Link>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default TopHeader;
