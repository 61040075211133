import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';

const CreditCardSection = () => {

    return (
        <div className="calculateWrapper">
            <div className="innerWrap">

                <div className="formWrapper">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="topButtonsRow">
                                        <button type="button" className="btn showBtn me-4">
                                            Show VA
                                        </button>

                                        <button type="button" className="btn showAllBtn">
                                            Show All
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div className="loanSection">
                                <div className="row">
                                    <div className="col-md-2 col-12">
                                        <div className="loanWrap text-start">
                                            <div className="logo">
                                                <img
                                                    src={
                                                        "https://m.foolcdn.com/media/affiliates/original_images/PenFed_Logo.png"
                                                    }
                                                    className="img-fluid"
                                                    alt="logo"
                                                />
                                            </div>

                                            <p className="number">NMLS: 401822</p>
                                        </div>
                                    </div>

                                    <div className="col-md-2 col-6">
                                        <div className="loanWrap">
                                            <p className="heading">APR</p>

                                            <p className="text">5.990%</p>

                                            <p className="smallText">30 Year fixed</p>

                                            <button type="button" className="btn loanBtn">
                                                VA Loan
                                            </button>
                                        </div>
                                    </div>

                                    <div className="col-md-2 col-6">
                                        <div className="loanWrap">
                                            <p className="heading">Rate</p>

                                            <p className="text">5.750%</p>

                                            <p className="smallText">45 day lock</p>
                                        </div>
                                    </div>

                                    <div className="col-md-2 col-6">
                                        <div className="loanWrap">
                                            <p className="heading">Payment</p>

                                            <p className="text">$962</p>

                                            <p className="smallText">Monthly</p>
                                        </div>
                                    </div>

                                    <div className="col-md-2 col-6">
                                        <div className="loanWrap">
                                            <p className="heading">Fees & Points</p>

                                            <p className="text">$4,324</p>

                                            <p className="smallText">Includes 0.375 pts ($618)</p>
                                        </div>
                                    </div>

                                    <div className="col-md-2 d-flex justify-content-center align-items-center">
                                        <div className="detailBtnWrap">
                                            <button type="button" className="btn accountBtn w-100">
                                                View Details
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="loanSection">
                                <div className="row">
                                    <div className="col-md-2 col-12">
                                        <div className="loanWrap text-start">
                                            <div className="logo">
                                                <img
                                                    src={
                                                        "https://m.foolcdn.com/media/affiliates/original_images/PenFed_Logo.png"
                                                    }
                                                    className="img-fluid"
                                                    alt="logo"
                                                />
                                            </div>

                                            <p className="number">NMLS: 401822</p>
                                        </div>
                                    </div>

                                    <div className="col-md-2 col-6">
                                        <div className="loanWrap">
                                            <p className="heading">APR</p>

                                            <p className="text">5.990%</p>

                                            <p className="smallText">30 Year fixed</p>
                                        </div>
                                    </div>

                                    <div className="col-md-2 col-6">
                                        <div className="loanWrap">
                                            <p className="heading">Rate</p>

                                            <p className="text">5.750%</p>

                                            <p className="smallText">45 day lock</p>
                                        </div>
                                    </div>

                                    <div className="col-md-2 col-6">
                                        <div className="loanWrap">
                                            <p className="heading">Payment</p>

                                            <p className="text">$962</p>

                                            <p className="smallText">Monthly</p>
                                        </div>
                                    </div>

                                    <div className="col-md-2 col-6">
                                        <div className="loanWrap">
                                            <p className="heading">Fees & Points</p>

                                            <p className="text">$4,324</p>

                                            <p className="smallText">Includes 0.375 pts ($618)</p>
                                        </div>
                                    </div>

                                    <div className="col-md-2 d-flex justify-content-center align-items-center">
                                        <div className="detailBtnWrap">
                                            <button type="button" className="btn accountBtn w-100">
                                                View Details
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="lendersRow border-0">
                                <p>
                                    Disclosure : <strong>Rate data provided by</strong>{" "}
                                    <Link to={"/"} className="bottomLink">
                                        RateUpdate.com
                                    </Link>
                                    .
                                    <strong>
                                        {" "}
                                        Displayed by ICB, a division of Mortgage Research Center,
                                        NMLS #1907, Equal Housing Opportunity.
                                    </strong>
                                    Payments do not include taxes, insurance premiums or private
                                    mortgage insurance if applicable. Actual payments will be
                                    greater with taxes and insurance included.
                                    <Link to={"/"} className="bottomLink">
                                        Rate and product details.
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreditCardSection;
