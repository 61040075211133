import React, { useEffect, useState } from "react";
import { Collapse, Table, Spin, Input } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { BiTransferAlt } from "react-icons/bi";
import { MdOutlineFileDownload } from "react-icons/md";
import { useGetTransactionQuery } from "../../store/getSlice";
import Moment from "react-moment";
import { NavLink } from "react-router-dom";
import { fileUrl } from "../../Helper";
import { LoadingOutlined } from '@ant-design/icons';
import PaginationOptionsHelper from "../../Helpers/paginationOptionsHelper"

const TransactionSummary = () => {
    const [tableSortBy, setTableSortBy] = useState(null);
    const [columnName, setColumnName] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [searchText, setSearchText] = useState(null);
    const [loader, setLoader] = useState(false);
    const { data: getTransaction, isLoading: isTransactionLoading } = useGetTransactionQuery(`?page=${currentPage}&per_page=${pageSize}${searchText ? `&search=${searchText}` : ""}${(tableSortBy && columnName ? `&sort_direction=${tableSortBy}&sort_by=${columnName}` : "")}`)

    function formatString(str) {
        const noUnderscores = str.replace(/_/g, ' ');
        const formatted = noUnderscores.charAt(0).toUpperCase() + noUnderscores.slice(1);

        return formatted;
    }

    useEffect(() => {
        if (getTransaction) {
            setLoader(false)
        }

        if(getTransaction?.data?.length < 5){
              setPageSize(getTransaction?.data?.length)
        }
    }, [getTransaction])

     const pageSizeOptions = PaginationOptionsHelper(getTransaction?.meta?.total);

    const columns = [
        {
            title: 'S.No.',
            dataIndex: 'serial',
            key: 'serial',
            render: (text, record, index) => {
                const startSerial = (currentPage - 1) * pageSize + 1;
                return startSerial + index;
            },

        },
        {
            title: 'Transaction ID',
            dataIndex: 'transaction_id',
            key: 'transaction_id',
            sorter: true,
        },
        {
            title: 'Date',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (text, record) => (
                <Moment format='MM/DD/YYYY'>{record?.created_at}</Moment>
            ),
            sorter: true,
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (text, record) => {
                const amount = parseFloat(record?.amount);

                if (record?.status === 'refunded' || record?.status === 'partial_refunded') {
                    // Format the amount as currency without the '+' sign
                    const formattedAmount = amount ? amount.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    }) : 'N/A';

                    // Prepend '+' or '-' based on the amount
                    const displayAmount = amount > 0 ? `+$${formattedAmount.slice(1)}` : formattedAmount;

                    return (
                        <span>{displayAmount}</span>
                    );
                } else {
                    // Format the amount as currency without the '+' sign
                    const formattedAmount = amount ? amount.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    }) : 'N/A';

                    // Prepend '+' or '-' based on the amount
                    const displayAmount = amount > 0 ? `-$${formattedAmount.slice(1)}` : formattedAmount;

                    return (
                        <span>{displayAmount}</span>
                    );
                }
            },
            sorter: true,

        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: ((record) => (
                <>
                    {formatString(record)}
                </>
            ))
        },
        {
            title: 'Recurring Interval',
            dataIndex: 'interval',
            key: 'interval',
            render: ((record, index) => (
                <>
                    {index.status === "success" ? record : '-'}
                </>
            ))
        },
        {
            title: 'Download Invoice',
            dataIndex: 'invoice',
            key: 'invoice',
            render: (text, record) => (
                <>
                    <NavLink
                        to={`${fileUrl}storage/invoices/${record?.transaction_id}.pdf`}
                        target="_blank"
                        className="btn downloadBtn"
                    >
                        <MdOutlineFileDownload />
                    </NavLink>
                </>
            ),
        },

    ];

    const handleSearchChange = (e) => {
        setLoader(true)
        setCurrentPage(1)
        setPageSize(5)
        setSearchText(e?.target?.value || null);
    };

    return (
        <div className="transactionWrapper">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="sectionHeader">
                            <h3 className="sectionHeading">Transactions Summary</h3>
                            <Input.Search
                                placeholder="Search..."
                                allowClear
                                value={searchText}
                                onChange={handleSearchChange}
                                className="tableSearchInput"
                                style={{ width: "20%" }}
                            />
                        </div>
                        <div className="sectionContent">
                            <Collapse
                                accordion
                                ghost
                                bordered={false}
                                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? -90 : 90} />}
                                expandIconPosition='end'
                                className="transactionCollapse"
                                defaultActiveKey={[1]}
                                items={[
                                    {
                                        key: '1',
                                        label: <p className="panelHeader"><BiTransferAlt /> View Transactions</p>,
                                        children: (
                                            // <div className="tableWrapper table-responsive table-loading">
                                            <div className={`tableWrapper table-responsive`}>
                                                <Table
                                                    className="transactionTable"
                                                    dataSource={loader || isTransactionLoading ? [] : getTransaction?.data?.map(item => ({ ...item, key: item.id }))}
                                                    columns={columns}
                                                    onChange={(pagination, filters, sorter) => {
                                                        if (sorter.order) {
                                                            const columnTitle = columns.find(col => col.dataIndex === sorter.field)?.dataIndex;
                                                            setColumnName(columnTitle);

                                                            if (sorter.order === "ascend") {
                                                                setTableSortBy("asc");
                                                            } else {
                                                                setTableSortBy("desc");
                                                            }
                                                        }
                                                    }}
                                                    pagination={{
                                                        total: getTransaction?.meta?.total || 0,
                                                        current: currentPage,
                                                        pageSize: pageSize,
                                                        onChange: (page, size) => {
                                                            setLoader(true);
                                                            setCurrentPage(page);
                                                            setPageSize(size);
                                                        },
                                                        pageSizeOptions: pageSizeOptions,
                                                        showSizeChanger: true,
                                                    }}
                                                    loading={{
                                                        spinning: loader || isTransactionLoading,
                                                        indicator: <Spin indicator={<LoadingOutlined spin />} />,
                                                    }}
                                                />

                                            </div>
                                        ),
                                    },
                                ]}
                            />

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TransactionSummary;