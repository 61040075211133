import React, { useEffect, useState } from "react";
import { SliderImageThree } from "../../constant/images";
import { message, Spin, Empty, Pagination } from "antd";
import { TbBed, TbVector } from "react-icons/tb";
import { LuBath } from "react-icons/lu";
import { FaHeart, FaLocationDot } from "react-icons/fa6";
import { FaRegHeart } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import {
    useAddFavouriteMutation,
    useRecentlyViewPropertiesMutation,
    useRemoveFavouriteMutation,
    useSearchPropertyMutation,
} from "../../store/apiSlice";
import propertyImg1 from '../../assets/images/propertyImg_1.png';
import propertyImg2 from '../../assets/images/propertyImg_2.png';
import propertyImg4 from '../../assets/images/propertyImg_4.png';
import propertyImg5 from '../../assets/images/propertyImg_5.png';
import propertyImg6 from '../../assets/images/propertyImg_6.png';
import { LoadingOutlined } from '@ant-design/icons';


const PropertyCard = ({ filterProperty, filterLoading, setSearchLoader, onPropertyCardData = () => { } }) => {

    const location = useLocation();
    const navigate = useNavigate();
    const [addViewProperty] = useRecentlyViewPropertiesMutation();
    const [addFavourite] = useAddFavouriteMutation();
    const [removeFavourite] = useRemoveFavouriteMutation();
    const [propertyList, setPropertyList] = useState();
    const [currentPage, setCurrentPage] = useState(location?.state?.currentPage);
    const [pageSize, setPageSize] = useState(5);
    const [messageApi, contextHolder] = message.useMessage();
    const [isAddSaving, setIsAddSaving] = useState(false)
    const [isRemoveSaving, setIsRemoveSaving] = useState(false)
    const [searchProperty, { isLoading: isSearchLoading }] = useSearchPropertyMutation({ query: `&page=${1}&per_page=${5}` })
    const success = (message) => {
        messageApi.open({
            type: "success",
            content: message,
        });
    };

    const errorMessage = (message) => {
        messageApi.open({
            type: "error",
            content: message,
        });
    };

    const toggleWishlist = async (id) => {
        let value = {
            property_id: id,
        };
        setIsAddSaving(true)
        try {
            const res = await addFavourite(value);
            if (res.data) {
                const updatedListings = propertyList.map((listing) =>
                    listing.listing_key === id ? { ...listing, is_favorite: true } : listing
                );
                setPropertyList(updatedListings);
                success("Added successfully");
            } else {
                errorMessage(res.error.data.message);
            }
        } catch {
            errorMessage("An unexpected error occurred");
        } finally {
            setIsAddSaving(false)
        }
    };
    const deletefavourite = async (id) => {
        setIsRemoveSaving(true)
        try {
            const res = await removeFavourite(id);
            if (res.data) {
                success(res.data.message);
                const updatedListings = propertyList.map((listing) =>
                    listing.listing_key === id ? { ...listing, is_favorite: false } : listing
                );
                setPropertyList(updatedListings);

            } else {
                errorMessage(res.error.data.message);
            }
        } catch {
            errorMessage("An unexpected error occurred");
        } finally {
            setIsRemoveSaving(false)
        }
    };
    useEffect(() => {
        setSearchLoader(isSearchLoading);
    }, [isSearchLoading]);

    const viewProperty = async (data, propertyData) => {
        let value = {
            property_id: data,
        };
        try {
            const res = await addViewProperty(value);
            if (res.data) {
                navigate("/property-details", {
                    state: {
                        id: data
                    }
                });
            } else {
                errorMessage(res.error.data.message);
            }
        } catch (error) {
            errorMessage("An unexpected error occurred");
        }
    };

    useEffect(() => {

        if (filterProperty) {
            setPropertyList(filterProperty?.data?.data);
        } else {
            setPropertyList([]);
        }
    }, [filterProperty]);

    const handleSearch = async (page) => {

        let data = {};
        const search = location?.state;

        if (search?.zip_code) {
            data.zip_code = search.zip_code;
            data.current_page = page;
            data.beds = search?.filterValue?.beds;
            data.baths = search?.filterValue?.baths;
            data.max_price = search?.filterValue?.max_price;
            data.min_price = search?.filterValue?.min_price;
        } else if (search?.nearMe) {
            data.nearme = true;
            data.current_page = page;
        } else if (search?.zip) {
            data.myzip = true;
            data.current_page = page;
        } else if (search?.searchTerm) {
            data.zip_code = search.searchTerm;
        }

        try {
            let query = `&per_page=${pageSize}`;
            if (currentPage !== undefined) {
                query += `&page=${currentPage}`;
            }

            const res = await searchProperty({ query, data });
            const { data: propertyData, error } = res?.data || {};

            if (propertyData) {
                onPropertyCardData({ propertyData, res });
                setPropertyList(propertyData);
            } else if (error) {
                setPropertyList([]);
                errorMessage(error.message || "An error occurred");
            }
        } catch (error) {
            errorMessage("An unexpected error occurred");
        }
    };


    useEffect(() => {
        handleSearch()
    }, [location?.state?.zip, location?.state?.nearMe, location?.state?.zip_code, currentPage, pageSize])

    const propertyImages = [
        propertyImg1,
        propertyImg2,
        propertyImg4,
        propertyImg5,
        propertyImg6,
    ];

    return (
        <div className="propertyCardsContainer">
            {isSearchLoading || filterLoading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: "40vh" }}>
                    <Spin indicator={<LoadingOutlined spin />} style={{ display: "flex", alignItems: "center", justifyContent: "center" }} size="large" />
                </div>

            ) : propertyList?.length ? (
                <>
                    {propertyList?.map((property, index) => {
                        // Use index to select the corresponding image from the array
                        const imageSrc = propertyImages[index % propertyImages.length] || SliderImageThree; // Fallback to a default image if none found

                        return (
                            <div className="card propertyCard" key={property?.listing_key}>
                                <div className="card-body">
                                    {contextHolder}
                                    <div className="propertyContent detailsContent">
                                        <div className="propertyImage propertyImageBox">
                                            <img
                                                onClick={() => viewProperty(property?.listing_key, property)}
                                                src={imageSrc}
                                                className="img-fluid"
                                                alt={`property-${property?.listing_key}`}
                                            />
                                            <p className="wishlist">
                                                {property?.is_favorite ? (
                                                    <FaHeart
                                                        onClick={() => {
                                                            if (!isRemoveSaving) {
                                                                deletefavourite(property?.listing_key);
                                                            }
                                                        }}
                                                    />
                                                ) : (
                                                    <FaRegHeart
                                                        onClick={() => {
                                                            if (!isAddSaving) {
                                                                toggleWishlist(property?.listing_key);
                                                            }
                                                        }}
                                                    />
                                                )}
                                            </p>
                                        </div>
                                        <div className="propertyDetails" onClick={() => viewProperty(property?.listing_key, property)}>
                                            <h4 className="name">{property?.unparsed_address}</h4>
                                            {property?.postal_city || property?.state_or_province || property?.country || property?.postal_code ? (
                                                <p className="location">
                                                    <FaLocationDot />{" "}
                                                    {`${property?.postal_city ?? ''}${property?.postal_city ? ',' : ''} 
                                                  ${property?.state_or_province ?? ''}${property?.state_or_province ? ',' : ''} 
                                                  ${property?.country ?? ''}${property?.postal_code ? '-' : ''} 
                                                  ${property?.postal_code ?? ''}`}
                                                </p>
                                            ) : ''}
                                            <div className="amenities">
                                                <span className="beds">
                                                    {property?.bedrooms_total ? <span className="beds"><TbBed />{property?.bedrooms_total} Beds</span> : ''}
                                                </span>
                                                <span className="bath">
                                                    {property?.bathrooms_total ? <span className="bath"><LuBath />{property?.bathrooms_total} Baths</span> : ''}
                                                </span>
                                                <span className="bath">
                                                    <TbVector /> {property?.lot_size} Sqft
                                                </span>
                                            </div>
                                            <div className="bottomDetails">
                                                <span className="dtl">{property?.property_type}</span>
                                                <span className="divider"></span>
                                                <span className="dtl">{property?.property_subtype}</span>
                                            </div>
                                        </div>
                                        <span className="divider"></span>
                                        <div className="propertyPrice" onClick={() => viewProperty(property?.listing_key, property)}>
                                            <div className="price">
                                                <h4>
                                                    {property?.price
                                                        ? property?.price?.toLocaleString('en-US', {
                                                            style: 'currency',
                                                            currency: 'USD',
                                                        })
                                                        : '$0.00'}
                                                </h4>
                                            </div>
                                            <div className="buttonWrap">
                                                <button
                                                    type="button"
                                                    className="btn detailBtn"
                                                    onClick={() => viewProperty(property?.listing_key, property)}
                                                >
                                                    Details
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                    <div className="d-flex justify-content-center align-items-center">
                        <Pagination
                            current={currentPage}
                            pageSize={pageSize}
                            total={searchProperty?.meta?.total}
                            onChange={(page, size) => {
                                setCurrentPage(page);
                                setPageSize(size);
                            }}
                        />
                    </div>
                </>
            ) : (
                <div className="emptyContainer">
                    <Empty
                        description={
                            <p className="noDataText">
                                No properties found, please modify your search criteria.
                            </p>
                        }
                    />
                </div>
            )}

            <>

            </>

        </div>
    );
};

export default PropertyCard;
